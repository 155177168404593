import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import * as Sentry from '@sentry/react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 0.1, // This controls the percentage of transactions (or traces) you capture for performance monitoring.
  enabled: process.env.REACT_APP_NODE_ENV === 'production',
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

/**MUI Datagrid license key */
LicenseInfo.setLicenseKey(
  'bba26bbc1d684533de41effc0225e53fTz05NDU3NixFPTE3NTMwMTQ1ODQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  // </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
