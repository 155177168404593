import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../../assets/colors';
import { Option } from '../../../interface';
import CustomToolTip from '../../custom-tooltip';
import { generateDarkColor } from '../../../helper/generateDarkColor';

interface Props {
  selectedVariantId?: string;
  options: any;
  onClick: (selectedColor: Option) => void;
  disabled?: boolean;
}

export const ColorPicker: React.FC<Props> = ({
  selectedVariantId = '#FFFFFF',
  options,
  onClick,
  disabled,
}) => {
  return (
    <StyledWrapper>
      {options?.map((option: any) => (
        <CustomToolTip key={option.label} title={option.label}>
          <Color
            disabled={disabled}
            active={
              option.id
                ? option.id === selectedVariantId
                : option.value === selectedVariantId
            }
            onClick={() => {
              !disabled && onClick(option);
            }}
            value={option.value}
          />
        </CustomToolTip>
      ))}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
`;

const Color = styled.div<{
  value: string;
  active?: boolean;
  disabled?: boolean;
}>`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  ${({ value }) =>
    value.includes('/')
      ? css`
          background: linear-gradient(
            to right,
            #${value.split('/')[0].toLocaleLowerCase().replace(/\s/g, '')} 50%,
            #${value.split('/')[1].toLocaleLowerCase().replace(/\s/g, '')} 50%
          );
        `
      : css`
          background-color: ${value};
        `}

  border: ${(props) =>
    props.active
      ? ` 3px solid${colors.blue[800]}`
      : props.value.includes('/')
      ? `1px solid #${generateDarkColor(props.value.split('/')[1])}`
      : `1px solid${generateDarkColor(props.value)}`};
  cursor: ${(props) => (props.disabled ? 'progress' : 'pointer')};
  box-sizing: border-box;
`;
