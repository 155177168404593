import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Address,
  Recipient,
  SampleOrderCart,
} from '../../interface/create-order-interface';
import { RootState } from '..';

interface SampleOrderInitialState {
  currentStep: 1 | 2 | 3;

  recipient: Recipient;
  shippingAddress: Address;
  billingAddress: Address;
  totalCost: {
    totalProductionCost: number;
    shippingCost: number;
  };
  selectedProducts: SampleOrderCart[];
  billingSameAsShippingAddress: boolean;
  sampleOrderPaymentId: string | null;
  attachPaymentMethod: boolean;
  outOfStockItems: string[];
}
const addressInitialState = {
  country: '',
  state: '',
  firstLine: '',
  address: '',
  city: '',
  zipCode: '',
};
const initialState: SampleOrderInitialState = {
  currentStep: 1,

  recipient: { email: '', fullName: '', phone: '' },
  billingAddress: addressInitialState,
  shippingAddress: addressInitialState,
  totalCost: { totalProductionCost: 0, shippingCost: 0 },
  selectedProducts: [],
  billingSameAsShippingAddress: false,
  sampleOrderPaymentId: null,
  attachPaymentMethod: false,
  outOfStockItems: [],
};

const sampleOrderSlice = createSlice({
  name: 'sampleOrder',
  initialState,
  reducers: {
    updateCurrentStep: (state, { payload }: PayloadAction<number>) => {
      state.currentStep = payload as 1 | 2 | 3;
    },
    addSelectedProduct: (state, { payload }: PayloadAction<any>) => {
      state.selectedProducts.push(payload);
    },
    setSelectedProduct: (state, { payload }: PayloadAction<any>) => {
      state.selectedProducts = payload;
    },
    setAddress: (
      state,
      { payload }: PayloadAction<{ address: Address; isBilling: boolean }>,
    ) => {
      const { address, isBilling } = payload;
      const {
        firstLine,
        city,
        state: addressState,
        zipCode,
        country,
      } = address;
      const fullAddress = `${firstLine}, ${city}, ${addressState}, ${zipCode} ${country}`;

      if (isBilling) {
        state.billingAddress = { ...address, address: fullAddress };
      } else {
        state.shippingAddress = { ...address, address: fullAddress };
      }
    },
    setRecipient: (state, { payload }: PayloadAction<Recipient>) => {
      state.recipient = payload;
    },
    setTotalCost: (state, { payload }: PayloadAction<any>) => {
      state.totalCost = payload;
    },
    setSampleOrderPaymentId: (state, { payload }: PayloadAction<any>) => {
      state.sampleOrderPaymentId = payload;
    },
    resetState: () => initialState,
    updateBillingSameAsShippingAddress: (
      state,
      { payload }: PayloadAction<{ billingSameAsShippingAddress: boolean }>,
    ) => {
      state.billingSameAsShippingAddress = payload.billingSameAsShippingAddress;
      if (payload.billingSameAsShippingAddress) {
        state.billingAddress = state.shippingAddress;
      } else {
        state.billingAddress = addressInitialState;
      }
    },
    setAttachPaymentMethod: (state, { payload }: PayloadAction<boolean>) => {
      state.attachPaymentMethod = payload;
    },
    setOutOfStockItems: (state, { payload }: PayloadAction<string[]>) => {
      state.outOfStockItems = payload;
    },
  },
});

export const {
  updateCurrentStep,
  addSelectedProduct,
  setSelectedProduct,
  // setShippingAddress,
  setTotalCost,
  setRecipient,
  resetState,
  updateBillingSameAsShippingAddress,
  setAddress,
  setSampleOrderPaymentId,
  setAttachPaymentMethod,
  setOutOfStockItems,
} = sampleOrderSlice.actions;
export const getSampleOrderData = (state: RootState): SampleOrderInitialState =>
  state.sampleOrder;
export default sampleOrderSlice.reducer;
