import { useNavigate } from 'react-router-dom';
import Button from '../../../common/button';
import Modal from '../../../common/modal';
import { FlexBox } from '../../../common/wrappers';
import Typography from '../../../typography';
import styled from 'styled-components';

interface TopBarCancelModalProps {
  onSave: any;
  onClose: () => void;
}
const TopBarCancelModal: React.FC<TopBarCancelModalProps> = ({
  onSave,
  onClose,
}) => {
  const navigate = useNavigate();
  return (
    <StyledWrapper>
      <Modal
        size="extraSmall"
        title={'Exit'}
        onClose={onClose}
        className="topbar-cancel-modal"
      >
        <FlexBox direction="column" gap="1rem">
          <FlexBox direction="column" gap="1rem">
            <Typography
              text={`You have unsaved changes. Choose an option to proceed`}
              fontSize="14px"
            />
          </FlexBox>

          <FlexBox gap="1rem" className="button-wrapper">
            {
              <Button
                label={'Save Changes'}
                onClick={onSave}
                appearance="secondary"
                size="fullWidth"
              />
            }
            <Button
              disabled={false}
              label={'Discard Changes'}
              onClick={() => navigate('/')}
              appearance="danger"
              size="fullWidth"
            />
          </FlexBox>
        </FlexBox>
      </Modal>
    </StyledWrapper>
  );
};

export default TopBarCancelModal;

const StyledWrapper = styled.div`
  position: absolute;
  .topbar-cancel-modal {
    height: fit-content;
    padding-block: 10px;
    width: 400px !important;
  }
  .button-wrapper {
    margin-left: auto;
    width: 70%;
  }
`;
