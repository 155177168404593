import React, { useEffect } from 'react';
import amplitude from 'amplitude-js';
import Button from '../common/button';
import {
  getSampleOrderData,
  setOutOfStockItems,
  updateCurrentStep,
} from '../../store/slices/sampleOrderSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import styled, { css } from 'styled-components';
import { useCreateSampleOrderMutation } from '../../store/services/orders';
import { CreateSampleOrderPayloadVariant } from '../../interface/sample-order-interface-v2';

interface GroupedProduct {
  id: string;
  variants: CreateSampleOrderPayloadVariant[];
}
interface FooterProps {
  onClose: () => void;
  setShowSuccessSampleOrder: (value: boolean) => void;
  isFulfillMentButtonDisabled?: boolean;
  setGeneratePaymentMethodId: (status: boolean) => void;
  generatePaymentMethodId: boolean;
  setPaymentError: (error: string) => void;
  setIsLoading: (value: boolean) => void;
  isShippingPageValid: boolean;
}

const Footer: React.FC<FooterProps> = ({
  onClose,
  setShowSuccessSampleOrder,
  isFulfillMentButtonDisabled,
  setGeneratePaymentMethodId,
  generatePaymentMethodId,
  setPaymentError,
  setIsLoading,
  isShippingPageValid,
}) => {
  const {
    currentStep,
    selectedProducts,
    shippingAddress,
    billingAddress,
    recipient,
    totalCost,
    sampleOrderPaymentId,
    attachPaymentMethod,
    outOfStockItems,
  } = useAppSelector(getSampleOrderData);

  const [createSampleOrder] = useCreateSampleOrderMutation();
  const dispatch = useAppDispatch();
  const groupSelectedProducts = () => {
    const groupedProducts: Record<string, GroupedProduct> = {};

    selectedProducts.forEach((item) => {
      const {
        productId,
        printSKUId,
        catalogSKUId,
        quantity,
        productColorID,
        variantId,
      } = item;

      if (groupedProducts[productId]) {
        groupedProducts[productId].variants.push({
          catalogSKUId,
          productColorID,
          quantity,
          printSKUId,
          variantId,
        });
      } else {
        groupedProducts[productId] = {
          id: productId,
          variants: [
            {
              catalogSKUId,
              productColorID,
              quantity,
              printSKUId,
              variantId,
            },
          ],
        };
      }
    });

    return Object.values(groupedProducts);
  };

  const processOrder = async () => {
    setIsLoading(true);
    const products = groupSelectedProducts();
    try {
      const sampleOrderPayload = {
        products: products,
        shippingAddress: shippingAddress,
        billingAddress: billingAddress,
        recipient: recipient,
        paymentMethodId: sampleOrderPaymentId,
        attachPaymentMethod: attachPaymentMethod,
      };
      const res: any = await createSampleOrder(sampleOrderPayload).unwrap();
      if (res?.success) {
        onClose();
        setShowSuccessSampleOrder(true);
        setPaymentError('');
      }
    } catch (error: any) {
      setGeneratePaymentMethodId(false);
      setPaymentError(error.data.message);
      /** get OOS catalogSKUIds if any */
      if (error.data?.outOfStockItems) {
        dispatch(setOutOfStockItems(error.data.outOfStockItems));
      }
    } finally {
      setGeneratePaymentMethodId(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const process = async () => {
      await processOrder();
    };
    if (generatePaymentMethodId && sampleOrderPaymentId) {
      process();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatePaymentMethodId, sampleOrderPaymentId]);

  const changeStep = async (next: boolean) => {
    setGeneratePaymentMethodId(false);
    if (next) {
      if (currentStep === 1) {
        amplitude.getInstance().logEvent('Sample Order - Products Selected');
      } else {
        amplitude
          .getInstance()
          .logEvent('Sample Order - Shipment Details Provided');
      }
      dispatch(updateCurrentStep(currentStep + 1));
    } else {
      dispatch(updateCurrentStep(currentStep - 1));
    }
  };

  const submitOrder = async () => {
    if (sampleOrderPaymentId === null && setGeneratePaymentMethodId) {
      setGeneratePaymentMethodId(true);
      return;
    }
    await processOrder();
  };

  return (
    <StyledWrapper floatButtonToRight={currentStep === 1}>
      {currentStep !== 1 && (
        <Button
          size="large"
          appearance="secondary"
          label="Previous step"
          onClick={() => changeStep(false)}
        />
      )}
      <Button
        size="large"
        appearance="primary"
        label={currentStep === 3 ? 'Fulfill order' : 'Next step'}
        onClick={() => (currentStep === 3 ? submitOrder() : changeStep(true))}
        disabled={
          currentStep === 3
            ? isFulfillMentButtonDisabled ||
              !totalCost.shippingCost ||
              selectedProducts.some((product) =>
                outOfStockItems.includes(product.catalogSKUId?.toString()),
              )
            : currentStep === 1
            ? selectedProducts.length === 0
            : isShippingPageValid || totalCost.shippingCost === 0
        }
      />
    </StyledWrapper>
  );
};

export default Footer;

const StyledWrapper = styled.div<{ floatButtonToRight: boolean }>`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgb(235, 235, 235);
  padding: 6px 0;
  ${({ floatButtonToRight }) =>
    floatButtonToRight &&
    css`
      button {
        margin-left: auto;
      }
    `};
`;
