import { fabric } from 'fabric';

export const createCircleOrnamentCanvas = (
  canvas: fabric.Canvas,
  color?: string,
) => {
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();
  const outerCircleRadius = 240;
  const smallCircleRadius = 12;

  // Outer dashed circle
  var outerLayout = new fabric.Circle({
    left: (canvasWidth - outerCircleRadius * 2) / 2,
    top: (canvasHeight - outerCircleRadius * 2) / 2,
    radius: outerCircleRadius,
    stroke: 'black',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#eeeeee',
    selectable: false,
    evented: false,
    startAngle: fabric.util.degreesToRadians(0),
    endAngle: fabric.util.degreesToRadians(20620),
    name: 'outer-path',
  });

  const innerPath = `M218.3349 432.6711C338.9179 432.6711 436.6698 334.9192 436.6698 214.3363 436.6698 108.0517 360.7262 19.5051 260.1427 0 261.6511 4.4628 262.4689 9.2436 262.4689 14.2155 262.4689 38.7191 242.6049 58.5828 218.1013 58.5828 193.598 58.5828 173.7339 38.7191 173.7339 14.2155 173.7339 9.2798 174.54 4.5324 176.0274.0975 75.6924 19.7987 0 108.2282 0 214.3363 0 334.9192 97.7519 432.6711 218.3349 432.6711Z`;

  // Small cutout circle at the top
  const innerLayout = new fabric.Path(innerPath, {
    stroke: 'grey',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#eeeeee',
    selectable: false,
    evented: false,
    strokeDashArray: [10, 10],
    name: 'inner-path',
  });

  // Add shapes to the canvas (temporarily for bounding box calculation)
  canvas.add(outerLayout);
  canvas.add(innerLayout);
  canvas.renderAll();

  // Get bounding rectangles
  const innerBoundingRect = innerLayout.getBoundingRect();

  // Center the inner path
  innerLayout.set({
    left: (canvasWidth - innerBoundingRect.width) / 2,
    top: (canvasHeight - innerBoundingRect.height) / 2 + 2,
  });

  // Update positions after setting
  innerLayout.setCoords();

  // Small cutout circle at the top
  var cutoutCircle = new fabric.Circle({
    left: (canvasWidth - smallCircleRadius * 2) / 2,
    top: (canvasHeight - outerCircleRadius * 2) / 2 + 27,
    radius: smallCircleRadius,
    stroke: 'black',
    strokeWidth: 1,
    fill: 'white',
    selectable: false,
    evented: false,
    name: 'cutout-circle',
  });

  canvas.sendToBack(innerLayout);
  canvas.sendToBack(cutoutCircle);
  canvas.sendToBack(outerLayout);

  canvas.requestRenderAll();
};

export const createOvalOrnamentCanvas = (
  canvas: fabric.Canvas,
  color?: string,
) => {
  // Get canvas dimensions
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();

  const smallCircleRadius = 13;

  // SVG path for the inner oval
  const innerOvalPath = `M148.05 58.0495C168.1808 58.0495 184.5 41.7305 184.5 21.5996 184.5 14.1277 182.2518 7.1808 178.3948 1.3987 243.941 22.2768 292.95 106.0983 292.95 206.3246 292.95 322.263 227.371 416.2495 146.475 416.2495 65.579 416.2495 0 322.263 0 206.3246 0 104.0832 50.9994 18.9131 118.5282.2162 114.17 6.2227 111.6 13.6111 111.6 21.5996 111.6 41.7305 127.9192 58.0495 148.05 58.0495Z`;

  // SVG path for the outer oval
  const outerOvalPath = `M338.85 233.325C338.85 297.5863 319.8847 355.7273 289.2672 397.7811 258.6488 439.8354 216.4262 465.75 169.875 465.75 123.3239 465.75 81.1012 439.8354 50.4828 397.7811 19.8651 355.7273.9 297.5863.9 233.325.9 169.0636 19.8651 110.9228 50.4828 68.8689 81.1012 26.8144 123.3239.9 169.875.9 216.4262.9 258.6488 26.8144 289.2672 68.8689 319.8847 110.9228 338.85 169.0636 338.85 233.325Z`;

  // Create the outer oval path object
  const outerLayout = new fabric.Path(outerOvalPath, {
    stroke: 'grey',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#eeeeee',

    selectable: false,
    evented: false,
    name: 'outer-path',
  });

  // Create the inner oval path object
  const innerLayout = new fabric.Path(innerOvalPath, {
    stroke: 'grey',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#eeeeee',

    selectable: false,
    evented: false,
    strokeDashArray: [10, 10],
    name: 'inner-path',
  });

  // Add shapes to the canvas (temporarily for bounding box calculation)
  canvas.add(outerLayout);
  canvas.add(innerLayout);

  // Render all objects on the canvas (temporarily)
  canvas.renderAll();

  // Get bounding rectangles
  const outerBoundingRect = outerLayout.getBoundingRect();
  const innerBoundingRect = innerLayout.getBoundingRect();

  // Center the outer oval
  outerLayout.set({
    left: (canvasWidth - outerBoundingRect.width) / 2,
    top: (canvasHeight - outerBoundingRect.height) / 2,
  });

  // Center the inner oval
  innerLayout.set({
    left: (canvasWidth - innerBoundingRect.width) / 2,
    top: (canvasHeight - innerBoundingRect.height) / 2,
  });

  // Update positions after setting
  outerLayout.setCoords();
  innerLayout.setCoords();

  // Small cutout circle at the top
  var cutoutCircle = new fabric.Circle({
    left: (canvasWidth - smallCircleRadius * 2) / 2,
    top: (canvasHeight - outerBoundingRect.height) / 2 + 32,
    radius: smallCircleRadius,
    stroke: 'black',
    strokeWidth: 1,
    fill: 'white',
    selectable: false,
    evented: false,
    name: 'cutout-circle',
  });

  canvas.sendToBack(cutoutCircle);
  canvas.sendToBack(innerLayout);
  canvas.sendToBack(outerLayout);
  canvas.requestRenderAll();
};

// Function to create a heart ornament canvas
export const createHeartOrnamentCanvas = (
  canvas: fabric.Canvas,
  color?: string,
) => {
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();

  const smallCircleRadius = 12;

  const outerHeartPath = `M248.5339 17.2592C266.8042 11.9317 308.0179.9029 335.9165.9029 414.9054.9029 478.9778 66.6199 478.9778 147.7364 478.9778 196.1503 454.433 249.575 414.2336 299.7287 374.0478 349.866 318.29 396.642 256.0396 431.7486 246.042 437.3871 233.8387 437.3871 223.8411 431.7486 161.5907 396.6415 105.8329 349.8651 65.6471 299.7277 25.448 249.5741.9029 196.1493.9029 147.7351.9029 66.6199 64.9753.9029 143.9642.9029 171.7304.9029 211.6586 11.8283 229.5501 17.1831 235.7451 19.0371 242.3244 19.0697 248.5339 17.2592Z`;
  const outerLayout = new fabric.Path(outerHeartPath, {
    stroke: 'black',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#eeeeee',
    selectable: false,
    evented: false,
    top: 75,
    left: 40,
    name: 'outer-path',
  });
  const innerHeartPath = `M248.0614 7.92C263.7083 3.7008 281.4121 0 295.372 0 365.2845 0 421.96 56.9527 421.96 127.208 421.96 211.9471 333.3502 313.9814 222.1072 374.3045 215.1644 378.0696 206.7956 378.0696 199.8528 374.3045 88.6098 313.9805 0 211.9462 0 127.2071 0 56.9527 56.6755 0 126.588 0 140.7089 0 158.3604 3.7868 173.8 8.0663V43.78C173.8 64.3139 190.4461 80.96 210.98 80.96 231.5139 80.96 248.16 64.3139 248.16 43.78V7.92H248.0614Z`;
  const innerLayout = new fabric.Path(innerHeartPath, {
    stroke: 'grey',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#eeeeee',
    selectable: false,
    evented: false,
    top: 95,
    left: 60,
    strokeDashArray: [10, 10],
    name: 'inner-path',
  });
  var cutoutCircle = new fabric.Circle({
    left: (canvasWidth - smallCircleRadius * 2) / 2,
    top: (canvasHeight - smallCircleRadius * 2) / 4 - 30,
    radius: smallCircleRadius,
    stroke: 'black',
    strokeWidth: 1,
    fill: 'white',
    selectable: false,
    evented: false,
    name: 'cutout-circle',
  });
  // Add elements to the canvas
  canvas.add(outerLayout);
  canvas.add(innerLayout);
  canvas.add(cutoutCircle);

  const outerBoundingRect = outerLayout.getBoundingRect();
  const innerBoundingRect = innerLayout.getBoundingRect();

  // Center the outer oval
  outerLayout.set({
    left: (canvasWidth - outerBoundingRect.width) / 2,
    top: (canvasHeight - outerBoundingRect.height) / 2,
  });

  // Center the inner oval
  innerLayout.set({
    left: (canvasWidth - innerBoundingRect.width) / 2,
    top: (canvasHeight - innerBoundingRect.height) / 2,
  });

  canvas.sendToBack(cutoutCircle);
  canvas.sendToBack(innerLayout);
  canvas.sendToBack(outerLayout);
  canvas.requestRenderAll();
};

export const createStarOrnamentCanvas = (
  canvas: fabric.Canvas,
  color?: string,
) => {
  const canvasHeight = canvas.getHeight();
  const canvasWidth = canvas.getWidth();

  const smallCircleRadius = 12;
  const outerStarPath = `M212.7002 23.9302 212.7256 23.8895 212.7476 23.8473C220.7144 9.0168 229.0494 2.3773 236.6886 2.3773 244.3066 2.3774 252.4342 8.9908 259.9744 23.8137L260.01 23.8831 260.0514 23.949 300.0945 87.4772C305.0513 95.3416 310.582 102.9478 317.1919 109.6204 329.4366 121.9828 339.5119 128.8796 355.658 136.8058 364.2071 141.0025 373.2404 144.0514 382.3982 146.4172L451.4626 164.257C461.0351 167.2585 467.4317 173.1473 469.9203 180.1847 472.3944 187.1831 471.1273 195.6671 464.674 204.1722L406.7505 266.3236 406.7345 266.3414 406.7184 266.3592C398.2149 276.0113 391.9512 290.0716 388.2116 304.7567 384.4745 319.4333 383.2108 334.9174 384.8444 347.5583L390.2168 434.7562 390.2202 434.8137 390.2278 434.8705C391.6211 445.5857 388.6898 454.0324 383.298 458.7598 377.9602 463.4381 369.8208 464.8119 359.9833 460.6051L359.9689 460.5992 359.9546 460.5932 272.3758 425.0542C272.3715 425.0525 272.3664 425.0499 272.3622 425.0483 250.0743 415.5232 223.7133 417.3667 201.5948 426.825L114.1734 464.2076C103.1882 468.3374 93.2238 466.6106 86.31 461.2695 79.3869 455.9217 75.3079 446.7851 76.4406 435.6568 76.4414 435.6492 76.4421 435.6416 76.443 435.634L87.8877 347.615C91.1864 322.2461 79.3913 292.5247 66.6954 272.8642L66.6468 272.7888 66.5907 272.7194 10.6285 203.3545 10.6055 203.3258 10.5812 203.2987C3.4732 195.2312 1.1505 186.4188 2.9616 179.279 4.7543 172.2128 10.7097 166.3452 21.1397 164.29L21.1552 164.2866 21.1707 164.2832 93.6969 148.5782C103.3761 146.4824 113.0705 143.7289 122.009 139.12 140.5776 129.5466 152.7648 118.1754 166.0312 100.1528L166.0735 100.0944 166.1116 100.0326 212.7002 23.9302Z`;
  const outerLayout = new fabric.Path(outerStarPath, {
    stroke: 'black',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#eeeeee',
    selectable: false,
    evented: false,
    name: 'outer-path',
  });

  const innerStarPath = `M228.7836.351C222.3217 10.4468 211.0085 17.1388 198.132 17.1388 185.4027 17.1388 174.2006 10.5982 167.7033.6944L148.9442 33.623C137.6432 53.4601 118.5406 67.6483 96.2846 72.7358L.0002 94.7456 64.5547 167.847C80.5072 185.9114 87.6475 210.1094 84.0586 233.9403L69.1163 333.1469 166.1884 291.4764C186.7177 282.6638 209.9647 282.6638 230.4939 291.4764L327.5661 333.1469 312.6239 233.9403C309.0349 210.1094 316.1752 185.9114 332.1278 167.847L396.682 94.7456 300.3978 72.7358C278.1418 67.6483 259.0392 53.4601 247.7381 33.623L228.7836.351Z`;
  const innerLayout = new fabric.Path(innerStarPath, {
    stroke: 'grey',
    strokeWidth: 1,
    fill: color ? `#${color}` : '#eeeeee',
    selectable: false,
    evented: false,
    strokeDashArray: [10, 10],
    name: 'inner-path',
  });

  // Add shapes to the canvas (temporarily for bounding box calculation)
  canvas.add(outerLayout);
  canvas.add(innerLayout);
  canvas.renderAll();

  // Get bounding rectangles
  const outerBoundingRect = outerLayout.getBoundingRect();
  const innerBoundingRect = innerLayout.getBoundingRect();

  // Center the outer star
  outerLayout.set({
    left: (canvasWidth - outerBoundingRect.width) / 2,
    top: (canvasHeight - outerBoundingRect.height) / 2,
  });

  // Center the inner star
  innerLayout.set({
    left: (canvasWidth - innerBoundingRect.width) / 2,
    top: (canvasHeight - innerBoundingRect.height) / 2 + 25,
  });

  // Update positions after setting
  outerLayout.setCoords();
  innerLayout.setCoords();

  // Small cutout circle at the top
  var cutoutCircle = new fabric.Circle({
    left: (canvasWidth - smallCircleRadius * 2) / 2,
    top: (canvasHeight - smallCircleRadius * 2) / 4 - 40,
    // top:
    //   (canvasHeight - outerBoundingRect.height) / 2 - outerBoundingRect.top / 2,
    radius: smallCircleRadius,
    stroke: 'black',
    strokeWidth: 1,
    fill: 'white',
    selectable: false,
    evented: false,
    name: 'cutout-circle',
  });

  canvas.sendToBack(innerLayout);
  canvas.sendToBack(cutoutCircle);
  canvas.sendToBack(outerLayout);

  canvas.requestRenderAll();
};

export const getOrnamentsModel = (catalogId: string) => {
  if (catalogId === '663b4d932100a7981b60e308') {
    return 'OR-CIRCLE';
  } else if (catalogId === '663b52812100a7981b60e30c') {
    return 'OR-OVAL';
  } else if (catalogId === '663b54d82100a7981b60e310') {
    return 'OR-HEART';
  } else if (catalogId === '663b542b2100a7981b60e30e') {
    return 'OR-STAR';
  } else {
    return undefined;
  }
};
