import Cookies from 'js-cookie';

export const setCookie = (
  cookieName: string,
  cookieValue: string,
  options?: Cookies.CookieAttributes,
) => {
  Cookies.set(cookieName, cookieValue, { ...options, domain: '.everbee.io' });
};

export const getCookie = (cookieName: string) => {
  return Cookies.get(cookieName);
};

export const removeCookie = (cookieName: string) => {
  Cookies.remove(cookieName);
};
