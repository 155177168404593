import React, { useEffect } from 'react';
import Details from '../../components/details';
import { TabletProps } from '../../interface';
import { useAppDispatch } from '../../store/hooks';
import { resetDetailsSlice } from '../../store/slices/detailsSlice';

const DetailsPage: React.FC<TabletProps> = ({ isTablet }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    /** resetting the details slice when the details page is unmounted */
    return () => {
      dispatch(resetDetailsSlice());
    };
  }, [dispatch]);
  return <Details isTablet={isTablet} />;
};

export default DetailsPage;
