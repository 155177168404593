import { SellerProductImage1 } from '../assets/icons';
import { isDescriptionAdded } from '../components/details/utils';
import { MyProductsResult } from '../interface/product-interface';

export const getCutomizedMyProductsResponse = (
  data: Array<MyProductsResult> | [],
) => {
  if (data.length > 0) {
    return data.map((item, index) => {
      return {
        id: item._id ?? (item as any)?.id,
        image:
          item.images && item.images[0] && item.images[0].imageUrl
            ? item.images[0].imageUrl
            : SellerProductImage1,
        images: item.images,
        productTitle: item.title,
        properties: item.properties,
        brand: item.brand,
        status: item.status,
        inventory: item.inventory,
        printProviderId: item.printProviderId,
        catalogId: item.catalogId,
        listingId: item.listingId,
        savingDesign: item.savingDesign,
        isAnyFieldsMissing:
          item.isAnyFieldsMissing ||
          !isDescriptionAdded(JSON.parse(item.description)),
      };
    });
  } else return [];
};
