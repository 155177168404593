/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from 'react';
import { ColoredCircle, CustomBtnWithIcon } from '../../../common/elements';
import { FlexBox } from '../../../common/wrappers';
import DesignElement from './element';
import { Canvas, IEvent } from 'fabric/fabric-impl';
import ReactDragListView from 'react-drag-listview';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  getObjects,
  getSelectedColors,
  setObjects,
  setSelectedColors,
} from '../../../../store/slices/editor';
import { IObject, JSONObject, ObjectTypes } from '../../../../interface';
import { onlyNumberWIthDecimalRegex, updateSrcInEditorData } from '../../utils';
import { AddIconSqaure, Cross } from '../../../../assets/icons';
import { fabricFieldsToKeep } from '../../../common/contants';
import Button from '../../../common/button';
import styled from 'styled-components';
import { colors } from '../../../../assets/colors';
import { ClickAwayListener } from '@mui/material';
import { useParams } from 'react-router-dom';
import CustomToolTip from '../../../custom-tooltip';
import { getUserData } from '../../../../store/slices/authSlice';
import { generateDarkColor } from '../../../../helper/generateDarkColor';
import { generateContrastingColor } from '../../../../helper/generateContrastingColor';
import { ReactComponent as CloseIcon } from '../../../../assets/images/cross.svg';
import TickMarkComponent from '../../../common/tick-mark-component';
import Typography from '../../../typography';
import { useTempUploadImageToGalleryMutation } from '../../../../store/services/gallery';
import { uploadEditorDataToS3 } from '../../../../helper/s3';
import Loader from '../../../common/loader';
import {
  useGetOrderByIdQuery,
  useUpdateProductPersonalizationForOrderMutation,
} from '../../../../store/services/orders';
import {
  Side,
  VariantEditorDataV2,
  VariantsV2,
} from '../../../../interface/catalog-interface-v2';
import { ProductType } from '../../../../interface/product-interface';


interface Props {
  isSellerEditor: boolean;
  onClickAddButton: () => void;
  selectedElementId: string;
  drawingBoard: Canvas | undefined;
  catalogVariants: VariantsV2[] | undefined;
  setSelectedVariant(selectedVariant: VariantsV2): void;
  selectedVariant: VariantsV2 | undefined;
  selectedAngle: string;
  anglesData: VariantEditorDataV2[];
  manageSpecificationDesign(status: boolean, colorCode: string): void;
  defaultEditorData: any;
  pricesMin?: number;
  pricesMax?: number;
  isBackSidePrintAdded?: any;
  isFrontSidePrintAdded?: any;
  showColorsModal: boolean;
  setShowColorsModal: any;
  onClose?: () => void;
  type?: string;
  isTablet?: boolean;
  undoAndRedoLoadingRef: React.MutableRefObject<boolean>;
  instructions?: string;
  setShowPersonalizeEditorModal?: (value: any) => void;
  productIdFromPersonalization?: string;
  transactionId?: number;
  editorDataFromProps?: any;
  printAreaDimensions: Side[] | undefined;
  printSides: string[];
  currentProductType: string | undefined;
}

const ElementList: React.FC<Props> = ({
  isSellerEditor,
  onClickAddButton,
  selectedElementId,
  drawingBoard,
  catalogVariants,
  setSelectedVariant,
  selectedVariant,
  selectedAngle,
  anglesData,
  manageSpecificationDesign,
  defaultEditorData,
  pricesMax,
  pricesMin,
  isBackSidePrintAdded,
  isFrontSidePrintAdded,
  setShowColorsModal,
  showColorsModal,
  onClose,
  type,
  isTablet,
  undoAndRedoLoadingRef,
  instructions,
  setShowPersonalizeEditorModal,
  productIdFromPersonalization,
  editorDataFromProps,
  printAreaDimensions,
  printSides,
  currentProductType,
  transactionId,
}) => {
  const { orderId } = useParams();
  const { refetch } = useGetOrderByIdQuery(
    {
      orderId,
    },
    {
      skip: !orderId,
    },
  );
  const selectedColors = useAppSelector(getSelectedColors);
  const { userInfo } = useAppSelector(getUserData);
  const files = useAppSelector(getObjects);
  const [tempUpload] = useTempUploadImageToGalleryMutation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const [updateProductPersonalizationInOrder] =
    useUpdateProductPersonalizationForOrderMutation();
  const updateData = (e: IEvent) => {
    const filesCopy = [...files];
    const index = filesCopy.findIndex(
      (obj: any) =>
        obj.id === e.target?.toDatalessObject(fabricFieldsToKeep).id,
    );
    filesCopy[index] = e.target?.toDatalessObject(fabricFieldsToKeep);
  };
  const side = printAreaDimensions?.find((s) => s.side === selectedAngle);
  const { designFileDimensionsInPX } = side || {
    designFileDimensionsInPX: { width: 0, height: 0 },
  };
  useEffect(() => {
    drawingBoard?.on('object:rotating', (e: IEvent) => {
      updateData(e);
    });

    drawingBoard?.on('object:scaling', (e: IEvent) => {
      updateData(e);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawingBoard]);

  const handleElementSelection = (clickedId: string | undefined) => {
    if (clickedId === selectedElementId) {
      drawingBoard?.discardActiveObject();
    } else {
      const allElements = drawingBoard?.getObjects();
      const elementToSelect = allElements?.find(
        (element: any) => element.id === clickedId,
      );
      if (elementToSelect) {
        drawingBoard?.setActiveObject(elementToSelect);
      }
    }
    drawingBoard?.renderAll();
  };

  const moveObj = (obj: JSONObject, index: number) => {
    const allElements: any = drawingBoard?.getObjects();
    const element = allElements?.find((element: any) => element.id === obj.id);
    if (element) drawingBoard?.moveTo(element, index);
  };

  const dragProps = {
    onDragEnd(fromIndex: number, toIndex: number) {
      const data = [...files];
      const item = data.splice(fromIndex, 1)[0];
      data.splice(toIndex, 0, item);
      data.reverse().map((obj, index) => moveObj(obj, index + 1));
      drawingBoard?.renderAll();
      dispatch(setObjects({ objects: data.reverse() }));
    },
    nodeSelector: '.item-header',
    handleSelector: '.draggable',
    lineClassName: 'global-drag-line',
  };

  const onChange = (value: any, field: string, elementId: string) => {
    const filesCopy: any = [...files];
    const index = files.findIndex((obj: any) => obj.id === elementId);
    let element: any = drawingBoard?.getActiveObject();
    if (!element || element === null) {
      element = drawingBoard
        ?.getObjects()
        .find((ele: any) => ele.id === elementId);
    }

    if (field === 'angle' && onlyNumberWIthDecimalRegex.test(value)) {
      let updatedAngle = 0;
      if (value > 360) {
        updatedAngle = value - 360;
      } else if (value < 0) {
        updatedAngle = 360 + value;
      } else {
        updatedAngle = value;
      }
      filesCopy[index] = { ...files[index], angle: updatedAngle };
      element?.rotate(value).setCoords();
      const allElements = drawingBoard?.getObjects();
      const elementToSelect = allElements?.find(
        (element: any) => element.id === elementId,
      );
      drawingBoard?.fire('object:modified', { target: elementToSelect });
    } else if (field === 'personalizationType') {
      element?.set({
        personalizationData: {
          ...element.personalizationData,
          personalizationType: value,
        },
      });
      filesCopy[index] = {
        ...files[index],
        personalizationData: {
          ...files[index].personalizationData,
          personalizationType: value,
        },
      };
    } else if (field === 'isLocked') {
      element?.set({
        hasControls: !value,
        lockMovementX: value,
        lockMovementY: value,
        personalizationData: {
          ...element.personalizationData,
          isLocked: value,
        },
      });

      filesCopy[index] = {
        ...files[index],
        hasControls: !value,
        lockMovementX: value,
        lockMovementY: value,
        personalizationData: {
          ...files[index].personalizationData,
          isLocked: value,
        },
      };
    } else if (field === 'height') {
      element.scaleToHeight(value).setCoords();
      drawingBoard?.fire('object:modified', { target: element });
    } else if (field === 'width') {
      element.scaleToWidth(value).setCoords();
      drawingBoard?.fire('object:modified', { target: element });
    } else if (field === 'scale') {
      element
        .set({
          scaleY: value,
          scaleX: value,
        })
        .setCoords();
      drawingBoard?.fire('object:modified', { target: element });
    } else {
      element.set({
        [field]: value,
      });
      filesCopy[index] = {
        ...files[index],
        [field]: value,
      };
    }
    dispatch(setObjects({ objects: filesCopy }));
    drawingBoard?.renderAll();
  };

  const handleColorSelection = (variant: VariantsV2) => {
    const copy = [...selectedColors];
    const index = copy.findIndex((color) => color.id === variant.id);
    if (copy.length === 1 && variant.id === copy[0].id) {
      return;
    }
    if (index >= 0) {
      copy.splice(index, 1);
    } else {
      copy.push(variant);
    }
    dispatch(
      setSelectedColors({
        data: copy,
      }),
    );
    if (index >= 0) {
      setSelectedVariant(copy[0]);
    }
  };

  const isDesignUpdated = () => {
    let disabled = false;
    if (editorDataFromProps) {
      const variantDatas = anglesData;
      let currentVariantEditorData = variantDatas.find(
        (variant) => variant.id === selectedVariant?.id,
      );
      if (currentVariantEditorData?.variantSpecificDesign) {
        disabled =
          currentVariantEditorData.front === null &&
          currentVariantEditorData.back === null;
      } else {
        disabled =
          defaultEditorData.front === null && defaultEditorData.back === null;
      }
    }
    return disabled;
  };

  const savePersonalizationData = async () => {
    setIsLoading(true);
    const copyDrawingBoard = drawingBoard;
    const objects = copyDrawingBoard
      ?.toJSON(fabricFieldsToKeep)
      .objects.filter(
        (obj) =>
          obj.type === ObjectTypes.image || obj.type === ObjectTypes.text,
      );
    const dataToExtract: any = copyDrawingBoard?.toJSON(fabricFieldsToKeep);
    if (objects && dataToExtract) {
      dataToExtract.objects = objects;
    }
    delete dataToExtract?.clipPath;
    delete dataToExtract?.backgroundImage;
    const jsonData =
      dataToExtract.objects.length === 0 ? null : JSON.stringify(dataToExtract);

    const variantToUpdate = anglesData.find(
      (angle) => angle.id === selectedVariant?.id,
    );
    let variantDatas = anglesData;
    let copyDefaultData = defaultEditorData;
    let currentVariantEditorData = variantDatas.find(
      (variant) => variant.id === selectedVariant?.id,
    );
    if (currentVariantEditorData?.variantSpecificDesign) {
      variantDatas = variantDatas.map((variant) => {
        if (variant.id === selectedVariant?.id) {
          return {
            ...variant,
            [selectedAngle]: jsonData,
          };
        }
        return variant;
      });
    } else {
      copyDefaultData = {
        ...defaultEditorData,
        [selectedAngle]: jsonData,
      };
    }
    const images: any = {};
    for (const printSide of printSides || []) {
      const formData = new FormData();
      const dataToUse = variantToUpdate?.variantSpecificDesign
        ? variantToUpdate
        : copyDefaultData;
      if (dataToUse[printSide + 'image'] && dataToUse[printSide] !== null) {
        formData.append(
          `images`,
          (dataToUse as any)[printSide + 'image'],
          `${printSide}Design.png`,
        );
        const { src } = await tempUpload({ formData }).unwrap();
        images[printSide] = src;
      }
    }
    delete copyDefaultData.frontimage;
    delete copyDefaultData.backimage;
    const removeImagesFromVariantEditorData = async (variants: any[]) => {
      return variants.map(({ frontimage, backimage, ...rest }) => rest);
    };
    variantDatas = await removeImagesFromVariantEditorData(variantDatas);
    const updatedVariantEditorData = await Promise.all(
      variantDatas.map((variant) => updateSrcInEditorData(variant)),
    );
    const updatedDefaultEditorData = await updateSrcInEditorData(
      copyDefaultData,
    );
    const editorData = {
      defaultEditorData: updatedDefaultEditorData,
      variantEditorData: updatedVariantEditorData,
    };
    const editorDataFileName = `${userInfo.id + Date.now()}-editorData`;
    const editorDataResponse = await uploadEditorDataToS3(
      editorDataFileName,
      editorData,
    );
    const data = {
      orderId: orderId ?? '',
      productId: productIdFromPersonalization,
      transactionId,
      images,
      editorData: editorDataResponse.url,
    };

    const res = await updateProductPersonalizationInOrder(data);
    if (res) {
      setIsLoading(false);
      refetch();
      setShowPersonalizeEditorModal?.(false);
    }
  };

  const currentAngleData = anglesData.find(
    (variant) =>
      variant.id === selectedVariant?.id ||
      ((selectedVariant?.connectedVariants || []) as any)
        .map((a: { id: any }) => a.id)
        .includes(variant.id),
  );

  const showSpecificationButton =
    anglesData.filter((variant) => variant.variantSpecificDesign === false)
      .length > 1;
  return (
    <FlexBox
      direction="column"
      className="responsiveness-styles"
      justifyContent="space-between"
      style={{
        height: '100%',
      }}
    >
      {isLoading && <Loader />}
      {isSellerEditor && (
        <div>
          <div
            className="fs-18"
            color="grey900"
            style={{ padding: '0 0 15px ' }}
          >
            Personalize Instructions
          </div>
          <div className="fs-14 grey-wrapper">{instructions}</div>
        </div>
      )}
      <span className="fs-18" color="grey900" style={{ padding: '10px' }}>
        Product Setting
      </span>
      {onClose && (
        <CloseIcon
          className="pointer-cursor"
          onClick={onClose}
          style={{ position: 'absolute', paddingLeft: '94%' }}
        />
      )}
      <FlexBox
        direction="column"
        className={`flex-wrapper show-no-scrollbar ${
          type ? 'with-padding' : ''
        }`}
      >
        {!isSellerEditor ? (
          <>
            <div
              style={{
                border: `1px solid ${colors.grey[300]}`,
                background: `${colors.grey[100]}`,
                borderRadius: '6px',
              }}
            >
              {type !== 'layers' && (
                <>
                  <FlexBox justifyContent="space-between" direction="column">
                    <span
                      className="fs-16"
                      color="grey900"
                      style={{ padding: '10px 10px 7px' }}
                    >
                      Colors
                    </span>
                    <span
                      className="fs-12 grey600"
                      style={{ padding: '2px 10px' }}
                    >
                      Select preferred color variants
                    </span>
                  </FlexBox>
                  <FlexBox
                    wrap="wrap"
                    alignItems="center"
                    gap="5px"
                    style={{ margin: '10px' }}
                  >
                    <ClickAwayListener
                      onClickAway={() => setShowColorsModal(false)}
                    >
                      <AddColor
                        onClick={() => setShowColorsModal(true)}
                        className="pointer-cursor"
                      >
                        <span style={{ color: 'white' }}>+</span>
                        {showColorsModal && (
                          <ColorSelectionPopUp
                            className="box"
                            isTablet={isTablet ? isTablet : false}
                          >
                            <p className="fs-14 grey800">
                              {selectedColors.length} of{' '}
                              {catalogVariants?.length} colors selected
                            </p>
                            <div className="colors-area">
                              {catalogVariants?.map((variant) => (
                                <CustomToolTip
                                  key={variant.id}
                                  title={
                                    <Typography
                                      text={variant.label}
                                      fontSize="0.8rem"
                                      classname="box p-10px"
                                    />
                                  }
                                >
                                  <ColoredCircle
                                    onClick={() =>
                                      handleColorSelection(variant)
                                    }
                                    className="tick-mark-wrapper"
                                    colorCode={
                                      variant.label.includes('/')
                                        ? variant.colorHex
                                        : '#' + variant.colorHex
                                    }
                                    height={24}
                                    width={24}
                                  >
                                    {selectedColors.findIndex(
                                      (color: VariantsV2) =>
                                        color.id === variant.id,
                                    ) >= 0 && (
                                      <TickMarkComponent
                                        darkColor={generateContrastingColor(
                                          '#' + variant.colorHex,
                                        )}
                                        height={14}
                                        width={14}
                                        className="tick-mark"
                                        stroke={generateContrastingColor(
                                          '#' + variant.colorHex,
                                        )}
                                      />
                                    )}
                                  </ColoredCircle>
                                </CustomToolTip>
                              ))}
                            </div>
                          </ColorSelectionPopUp>
                        )}
                      </AddColor>
                    </ClickAwayListener>

                    {selectedColors.map((color: VariantsV2) => (
                      <ColorCircleWrapper
                        isSelected={selectedVariant?.id === color.id}
                        onClick={() => setSelectedVariant(color)}
                        className="pointer-cursor"
                        key={color.id}
                      >
                        {selectedColors.length > 1 && (
                          <img
                            src={Cross}
                            alt="close"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleColorSelection(color);
                            }}
                          />
                        )}
                        <CustomToolTip
                          title={
                            <Typography
                              text={color.label}
                              fontSize="0.8rem"
                              classname="box p-10px"
                            />
                          }
                        >
                          <ColoredCircle
                            colorCode={
                              color.label.includes('/')
                                ? color.colorHex
                                : '#' + color.colorHex
                            }
                            height={24}
                            width={24}
                            style={{
                              border:
                                selectedVariant?.id === color.id ? 'none' : '',
                            }}
                          />
                        </CustomToolTip>
                      </ColorCircleWrapper>
                    ))}
                  </FlexBox>
                </>
              )}
              {currentAngleData &&
                selectedColors.length > 1 &&
                type !== 'layers' && (
                  <div style={{ margin: '18px 10px' }}>
                    {currentAngleData.variantSpecificDesign ? (
                      <span
                        onClick={() =>
                          manageSpecificationDesign(false, currentAngleData.id)
                        }
                        className="pointer-cursor grey800 fs-12 box color-section-info"
                      >
                        Revert{' '}
                        <b>
                          {currentAngleData?.label || currentAngleData?.color}
                        </b>{' '}
                        to default design

                      </span>
                    ) : (
                      showSpecificationButton && (
                        <span
                          onClick={() =>
                            manageSpecificationDesign(true, currentAngleData.id)
                          }
                          className="pointer-cursor grey800 fs-12 box color-section-info"
                        >
                          Make a specific design for{' '}
                          <b>
                            {currentAngleData?.label || currentAngleData?.color}
                          </b>

                        </span>
                      )
                    )}
                  </div>
                )}
            </div>

            {type !== 'details' && (
              <>
                <FlexBox
                  direction="column"
                  gap="0.4rem"
                  style={{ padding: '14px 0 12px' }}
                >
                  <span className="grey800 fs-18 ">Product design</span>
                  <span className="grey800 fs-14 ">
                    Print area size {designFileDimensionsInPX.width} ×{' '}
                    {designFileDimensionsInPX.height} px (300 DPI)
                  </span>
                </FlexBox>
                <CustomBtnWithIcon
                  onClick={() => {
                    onClickAddButton();
                    onClose && onClose();
                  }}
                >
                  <img src={AddIconSqaure} alt="Add" />
                  <p style={{ fontSize: '14px' }}>Add design element</p>
                </CustomBtnWithIcon>{' '}
              </>
            )}
          </>
        ) : (
          <>
            <p className="grey800 fs-14">Customize your product</p>
            <CustomBtnWithIcon
              onClick={() => {
                onClickAddButton();
                onClose && onClose();
              }}
            >
              <img src={AddIconSqaure} alt="Add" />
              <p style={{ fontSize: '14px' }}>Add design element</p>
            </CustomBtnWithIcon>
          </>
        )}
        {type !== 'details' && (
          <ReactDragListView {...dragProps}>
            {(files || []).map((element: IObject, index: number) => (
              <DesignElement
                onChange={onChange}
                drawingBoard={drawingBoard}
                index={index}
                onExpandClick={() => handleElementSelection(element.id)}
                isExpanded={element.id === selectedElementId}
                key={index}
                element={element}
                undoAndRedoLoadingRef={undoAndRedoLoadingRef}
                printAreaDimensions={printAreaDimensions}
                selectedAngle={selectedAngle}
              />
            ))}
          </ReactDragListView>
        )}
      </FlexBox>
      {isSellerEditor && type !== 'details' && (
        <FlexBox className="buttons">
          <Button
            label={'Cancel'}
            appearance="secondary"
            onClick={() => {
              setShowPersonalizeEditorModal?.(false);
            }}
            className="responsive-btn"
          />
          <Button
            label={'Save Changes'}
            appearance="primary"
            onClick={() => {
              savePersonalizationData();
            }}
            disabled={isDesignUpdated()}
            className="responsive-btn"
            // disabled={!personalization.frontDesign || !personalization.backDesign}
          />
        </FlexBox>
      )}
      {!isSellerEditor && pricesMin && pricesMax && type === 'details' && (
        <FlexBox
          style={{ gap: '5px', height: '20%', padding: '0 25px 6px 18px' }}
          direction="column"
          justifyContent="flex-end"
        >
          <FlexBox justifyContent="space-between" className="responsive-text">
            <span className="fs-14 ">
              {currentProductType === ProductType.Mug ||
              currentProductType === ProductType.Ornament
                ? `Cost (inc. print)`
                : `Cost (inc. one side print)`}
            </span>
            <span className="fs-14">
              USD {pricesMin.toFixed(2)} - {pricesMax.toFixed(2)}
            </span>
          </FlexBox>
          {isBackSidePrintAdded() && isFrontSidePrintAdded() && (
            <FlexBox justifyContent="space-between" className="responsive-text">
              <span className="fs-14">Print (additional side)</span>
              <span className="fs-14">USD 5.00</span>
            </FlexBox>
          )}
          <FlexBox justifyContent="space-between" className="responsive-text">
            <strong>
              <span className="fs-14 grey900">Subtotal</span>
            </strong>
            <strong>
              <span className="fs-14">
                USD{' '}
                {isBackSidePrintAdded() && isFrontSidePrintAdded()
                  ? (pricesMin + 5).toFixed(2)
                  : pricesMin.toFixed(2)}{' '}
                -{' '}
                {isBackSidePrintAdded() && isFrontSidePrintAdded()
                  ? (pricesMax + 5).toFixed(2)
                  : pricesMax.toFixed(2)}
              </span>
            </strong>
          </FlexBox>{' '}
        </FlexBox>
      )}
    </FlexBox>
  );
};

const ColorCircleWrapper = styled.div<{ isSelected: boolean }>`
  padding: 1px;
  border: ${(props) =>
    props.isSelected ? `1.5px solid ${colors.blue[900]}` : 'none'};
  border-radius: 50%;
  position: relative;

  img {
    cursor: pointer;
    width: 15px;
    position: absolute;
    background-color: ${colors.white};
    border-radius: 50%;
    right: -5px;
    top: -8px;
    border: 1px solid ${colors.grey[300]};
    z-index: 2;
  }
`;

const AddColor = styled.div`
  position: relative;
  display: flex;
  align-items: self-end;
  justify-content: center;
  border-radius: 50%;
  background-color: ${colors.grey[700]};
  height: 26px;
  width: 26px;
  span {
    font-size: 20px;
    color: ${colors.black};
  }
`;

export const ColorSelectionPopUp = styled.div<{ isTablet: boolean }>`
  position: absolute;
  padding: 10px;
  width: ${(props) => (props.isTablet ? '450px' : '206px')};
  top: ${(props) => (props.isTablet ? '-44px' : '32px')};
  z-index: 9;
  left: ${(props) => (props.isTablet ? '32px' : '0')};
  right: 0;
  bottom: 0;
  height: fit-content;
  .colors-area {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    .tick-mark-wrapper {
      position: relative;
      img,
      .tick-mark {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: inline-block;
      }
      .tick-mark svg path {
        stroke: ${(props) => generateDarkColor('#ffffff')};
      }
    }
  }
`;

export default ElementList;
