import React from 'react';
import styled from 'styled-components';
import Modal from './modal';
import { FlexBox } from './wrappers';
import Typography from '../typography';
import { colors } from '../../assets/colors';
import Button from './button';
import { RedirectIcon } from '../../assets/icons';
import { openLinkInNewTab } from '../../helper/openLinkInNewTab';

interface Props {
  onClose: () => void;
}
const EtsyProductionPartnerModal: React.FC<Props> = ({ onClose }) => {
  return (
    <StyledWrapper>
      <Modal
        title="Add EverBee as a Production Partner"
        size="medium"
        className="production-partner-modal"
        onClose={onClose}
      >
        <FlexBox direction="column" gap="0.5rem">
          <FlexBox direction="column" gap="0.5rem" className="tile">
            <FlexBox gap="0.5rem" alignItems="center">
              <span className="grey-circle">1</span>
              <Typography
                text="Open Settings"
                fontSize="1rem"
                fontWeight="500"
                secondaryColorValue={900}
              />
            </FlexBox>
            <Typography
              text={
                <>
                  In <span className="fw-700">Shop Manager</span>, go to
                  <span className="fw-700"> Settings</span> →
                  <span className="fw-700"> Partners you work with.</span>
                </>
              }
              fontSize="0.87rem"
            />
          </FlexBox>
          {/* 2 */}
          <FlexBox direction="column" gap="0.5rem" className="tile">
            <FlexBox gap="0.5rem" alignItems="center">
              <span className="grey-circle">2</span>
              <Typography
                text="Add EverBee as a Partner"
                fontSize="1rem"
                fontWeight="500"
                secondaryColorValue={900}
              />
            </FlexBox>
            <Typography
              text={
                <>
                  Click
                  <span className="fw-700"> Add a new production partner </span>
                  , and fill out the form. In the
                  <span className="fw-700"> Production partner field, </span>
                  enter
                  <span className="fw-700"> EverBee </span>as the business name.
                  You can show the production partner name to your buyers or use
                  a general title like “Production Partner”.
                </>
              }
              fontSize="0.87rem"
            />
          </FlexBox>
          {/* 3 */}
          <FlexBox direction="column" gap="0.5rem" className="tile">
            <FlexBox gap="0.5rem" alignItems="center">
              <span className="grey-circle">3</span>
              <Typography
                text="Enter EverBee’s Location"
                fontSize="1rem"
                fontWeight="500"
                secondaryColorValue={900}
              />
            </FlexBox>
            <Typography
              text={
                <>
                  In the Location field, type{' '}
                  <span className="fw-700">
                    “Austin, Texas, United States Of America”.
                  </span>
                </>
              }
              fontSize="0.87rem"
            />
          </FlexBox>
          {/* 4 */}
          <FlexBox direction="column" gap="0.5rem" className="tile">
            <FlexBox gap="0.5rem" alignItems="center">
              <span className="grey-circle">4</span>
              <Typography
                text="Describe the Partnership with EverBee"
                fontSize="1rem"
                fontWeight="500"
                secondaryColorValue={900}
              />
            </FlexBox>
            <Typography
              text={
                <>
                  In the
                  <span className="fw-700"> About production partner </span>
                  section, type{' '}
                  <span className="fw-700">
                    “EverBee is the production partner that produces and ships
                    this order to my customer”.
                  </span>
                </>
              }
              fontSize="0.87rem"
            />
          </FlexBox>
          {/* 5 */}
          <FlexBox direction="column" gap="0.5rem" className="tile">
            <FlexBox gap="0.5rem" alignItems="center">
              <span className="grey-circle">5</span>
              <Typography
                text="Preview and Save"
                fontSize="1rem"
                fontWeight="500"
                secondaryColorValue={900}
              />
            </FlexBox>
            <Typography
              text={
                <>
                  Preview how this information will appear in your shop’s
                  <span className="fw-700"> About </span>section, then click
                  <span className="fw-700"> Save partner.</span>
                </>
              }
              fontSize="0.87rem"
            />
          </FlexBox>
          <Button
            icon={RedirectIcon}
            appearance="primary"
            label="Add on Etsy"
            onClick={() =>
              openLinkInNewTab('https://www.etsy.com/your/shops/me/partners')
            }
            className="redirect-btn"
            size="medium"
          />
        </FlexBox>
      </Modal>
    </StyledWrapper>
  );
};

export default EtsyProductionPartnerModal;

const StyledWrapper = styled.div`
  .production-partner-modal {
    height: fit-content;
    overflow: hidden;
    .modal-title p {
      font-weight: 700;
      font-size: 1.12rem;
    }
  }
  .tile {
    padding: 1rem;
    background-color: ${colors.grey[100]};
    border-radius: 1rem;
  }
  .grey-circle {
    border-radius: 50%;
    background-color: ${colors.grey[200]};
    padding: 0.2rem;

    color: ${colors.blue[900]};
    text-align: center;

    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem;

    width: 1.2rem;
  }
  .redirect-btn {
    margin-left: auto;
  }
`;
