import { GridColDef } from '@mui/x-data-grid-pro';
import { columns } from '../../../constants/tableColumnConstants';
import {
  RenderStatus,
  RenderCellWithCurrency,
  RenderImage,
  RenderOrderInfo,
  RenderText,
} from '../../../utils/render-cells';

export const ordersColumns = () => {
  const columnsArray: GridColDef[] = [
    {
      ...columns,
      field: 'store',
      headerName: 'Store',
      width: 70,
      renderCell: RenderImage,
    },
    {
      ...columns,
      field: 'order',
      headerName: 'Order',
      flex: 1.5,
      minWidth: 400,
      sortable: true,
      renderCell: RenderOrderInfo,
    },
    {
      ...columns,
      field: 'productionDate',
      headerName: 'Sent to production',
      width: 150,
      sortable: true,
      renderCell: (params) => RenderText(params, '14px'),
    },
    {
      ...columns,
      field: 'customer',
      headerName: 'Customer',
      width: 200,
      renderCell: (params) => RenderText(params, '14px'),
    },
    {
      ...columns,
      field: 'totalCost',
      headerName: 'Total cost',
      width: 150,
      renderCell: RenderCellWithCurrency,
    },
    // {
    //   ...columns,
    //   field: "tracking",
    //   headerName: "Tracking",
    //   renderCell: RenderTracking,
    // },
    {
      ...columns,
      field: 'status',
      headerName: 'Status',
      minWidth: 350,
      flex: 1,
      renderCell: (prop) => <RenderStatus prop={prop} />,
    },
  ];
  return columnsArray;
};
