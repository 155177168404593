import React, { useEffect } from 'react';
import Editor from '../../components/editor';
import { TabletProps } from '../../interface';
import { useAppDispatch } from '../../store/hooks';
import { resetEditorSlice } from '../../store/slices/editor';
import { clearPixiTextureCache } from '../../components/mockups/mockup-generator';

const EditorPage: React.FC<TabletProps> = ({ isTablet }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    /** resetting the editor slice when the editor page is unmounted */
    return () => {
      clearPixiTextureCache();
      dispatch(resetEditorSlice());
    };
  }, [dispatch]);

  return <Editor isTablet={isTablet} isSellerEditor={false} />;
};

export default EditorPage;
