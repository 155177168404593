import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import {
  GalleryImageObjectResponse,
  JSONObject,
  ObjectTypes,
  SingleProductVariants,
} from '../../interface';

import { VariantsV2 } from '../../interface/catalog-interface-v2';

interface EditorDataState {
  defaultEditorData: any;
  variantEditorData: any;
}
export interface InitialState {
  objects: JSONObject[];
  steps: JSONObject[];
  personalizationelementId: string;
  gallery: GalleryImageObjectResponse[];
  selectedColors: VariantsV2[];
  variants: SingleProductVariants[];
  focus: boolean;
  updatingJSON?: boolean;
  currentTab: 'newDesign' | 'myLibrary';
  base64Cache: Record<string, string>;
  updatingBlob: boolean;
  updatedEditorData: EditorDataState;

}

const initialState: InitialState = {
  objects: [],
  steps: [],
  personalizationelementId: '',
  gallery: [],
  selectedColors: [],
  variants: JSON.parse(sessionStorage.getItem('variants') ?? '[]') ?? [],
  focus: false,
  updatingJSON: false,
  currentTab: 'newDesign',
  base64Cache: {},
  updatingBlob: false,
  updatedEditorData: { defaultEditorData: '', variantEditorData: '' },

};
export const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    addObject: (state, action: PayloadAction<any>) => {
      state.objects.unshift(action.payload);
    },
    deleteObject: (state, action: PayloadAction<string>) => {
      state.objects = state.objects.filter(
        (file) => file.id !== action.payload,
      );
    },
    setObjects: (state, action: PayloadAction<{ objects: any }>) => {
      state.objects = action.payload.objects.filter(
        (obj: JSONObject) =>
          obj.type === ObjectTypes.image || obj.type === ObjectTypes.text,
      );
    },
    updateObject: (state, action: PayloadAction<any>) => {
      const index = state.objects.findIndex(
        (obj: any) => obj.id === action.payload.id,
      );
      state.objects[index] = action.payload;
    },
    updateSteps: (state, action: PayloadAction<any>) => {
      state.steps = action.payload;
    },
    updatePersonalizationElementId: (state, action: PayloadAction<string>) => {
      state.personalizationelementId = action.payload;
    },
    updateGallery: (
      state,
      action: PayloadAction<GalleryImageObjectResponse>,
    ) => {
      state.gallery.push(action.payload);
    },
    setSelectedColors: (
      state,
      action: PayloadAction<{
        data: VariantsV2[];
      }>,
    ) => {
      state.selectedColors = action.payload.data;
    },
    setFocus: (state, action: PayloadAction<boolean>) => {
      state.focus = action.payload;
    },
    setUpdatingJson: (state, action: PayloadAction<boolean>) => {
      state.updatingJSON = action.payload;
    },
    setCurrentTab: (
      state,
      action: PayloadAction<'myLibrary' | 'newDesign'>,
    ) => {
      state.currentTab = action.payload;
    },
    setBase64Cache(
      state,
      action: PayloadAction<{ blobUrl: string; base64: string }>,
    ) {
      state.base64Cache[action.payload.blobUrl] = action.payload.base64;
    },
    clearBase64Cache(state) {
      state.base64Cache = {};
    },
    resetEditorSlice: () => initialState,
    setIsBlobUpdating(state, action: PayloadAction<boolean>) {
      state.updatingBlob = action.payload;
    },
    setEditorData(state, action: PayloadAction<EditorDataState>) {
      state.updatedEditorData = action.payload;
    },

  },
});

export const {
  addObject,
  deleteObject,
  setObjects,
  updateObject,
  updateSteps,
  updatePersonalizationElementId,
  updateGallery,
  setSelectedColors,
  setFocus,
  setUpdatingJson,
  setCurrentTab,
  setBase64Cache,
  clearBase64Cache,
  resetEditorSlice,
  setIsBlobUpdating,
  setEditorData,

} = editorSlice.actions;

export const getObjects = (state: RootState) => state.editor.objects;
export const getSteps = (state: RootState) => state.editor.steps;
export const getPersonalizationElementId = (state: RootState) =>
  state.editor.personalizationelementId;
export const getStore = (state: RootState) => state.editor;
export const getSelectedColors = (state: RootState) =>
  state.editor.selectedColors;
export const getFocus = (state: RootState) => state.editor.focus;
export const getUpdatingJson = (state: RootState) => state.editor.updatingJSON;
export const getEditorData = (state: RootState): InitialState => state.editor;
export const getIsBlobUpadting = (state: RootState) =>
  state.editor.updatingBlob;

export default editorSlice.reducer;
