import { Canvas, Object } from 'fabric/fabric-impl';
import { fabric } from 'fabric';
import { SortFilter } from './orders-interface';
import { CatalogProductInfo, VariantsV2 } from './catalog-interface-v2';
import { Application, ICanvas } from 'pixi.js';

export interface Button {
  text: string;
  color: 'primary' | 'secondary';
  disabled?: boolean;
  onClick: () => void;
}

export interface ToolBarProps {
  type: string | undefined;
  top: number;
  left: number;
  drawingBoard?: Canvas | undefined;
  id?: string;
}

export interface IObject extends fabric.Object {
  id: string;
  name: string;
  personalizationData: PersonalizationData;
  text?: string;
  src?: string;
}

export interface Option {
  label: string;
  value: any;
  disable?: boolean;
}

interface PersonalizationData {
  personalizationType: string;
  isLocked: boolean;
  imageOptions?: GalleryImageObjectResponse[];
  textOptions?: {
    fonts: Option[];
    colors: Option[];
  };
}

export interface JSONObject extends Object {
  personalizationData: PersonalizationData;
  id?: string;
  name: string;
}

export enum ObjectTypes {
  image = 'custom-image',
  text = 'custom-text',
}

export enum PersonalizationType {
  ALLOW_BUYERS_TO_UPLOAD = 'allow-buyers-to-upload',
  GIVE_BUYERS_OPTION = 'give-buyers-option',
}

export interface GalleryImageObject {
  src: any;
  name: string;
}

export interface GalleryImageObjectResponse extends GalleryImageObject {
  id: string;
  userId?: string;
}

export interface GalleryImageResponse {
  gallery: GalleryImageObjectResponse;
}

export interface MyMockupResponseObject {
  userId: string;
  imageUrl: string;
  name: string;
  id: string;
  folderName: string[];
  currentFolder: string;
}
export interface MyMockupsResponse {
  data: {
    results: MyMockupResponseObject[];
    limit: number;
    page: number;
    totalPages: number;
    totalResults: number;
    allFolders: string[];
    currentFolder: string;
  };
}
export interface myDataMockupResponse {
  results: MyMockupResponseObject[];
  limit: number;
  page: number;
  totalPages: number;
  totalResults: number;
  allFolders: string[];
  currentFolder: string;
}
export interface MyMockupsResponseQueryParams {
  page: number;
  limit: number;
  idsToRemoveFromResponse: string[];
  folderName?: string;
}
export interface MyMockupResponseObject {
  userId: string;
  imageUrl: string;
  name: string;
  id: string;
}

export interface UploadMyMockupResponse {
  data: MyMockupResponseObject;
}
export interface ResultsArray {
  storeId: string;
  taxonomyId: number;
  mockupURL: string;
  title: string;
  variants: object[];
  brand: string;
  status: string;
  listingId: number;
  _id: string;
}

export interface SingleProductVariants {
  backImage: string;
  mainImage: string;
  color: string;
  colorHex: string;
  productColorID: number;
  [key: string]: string | number | boolean;
}

interface PublishSettings {
  showInStore: boolean;
  variantsVisibility: string;
  syncingOptions: SyncingOptions;
  shippingProfile: string;
}
export interface SingleProductResponse {
  data: {
    id: string;
    catalogProductId: string;
    model: string;
    storeId: string;
    mockupURL: string;
    editorData: any;
    title: string;
    variants: VariantsV2[];
    brand: string;
    status: Status;
    userId: string;

    description: string;
    isSizeTableAdded: boolean;
    tags: string[];
    listingId: number;
    publishingSettings: PublishSettings;
    taxonomyId: number;
    isPersonalizable: boolean;
    personalizationInstructions: string;
    shippingProfiles: ShippingProfile[];
    images: { imageUrl: string }[];
    unpublishedImages: { imageUrl: string }[];
    unpublishedVariants: SingleProductVariants[];
    type: string;
  };
}

export interface ShippingProfileDestination {
  shipping_profile_destination_id: number;
  shipping_profile_id: number;
  origin_country_iso: string;
  destination_country_iso: string;
  destination_region: string;
  primary_cost: {
    amount: number;
    divisor: number;
    currency_code: string;
  };
  secondary_cost: {
    amount: number;
    divisor: number;
    currency_code: string;
  };
  shipping_carrier_id: number;
  mail_class: null | string;
  min_delivery_days: number;
  max_delivery_days: number;
}

export interface ShippingProfile {
  isEverbeeShippingProfile: boolean;
  shippingProfileTitle: string;
  shippingProfileId: number;
  minProcessingDays: number;
  maxProcessingDays: number;
  shippingProfileDestinations: ShippingProfileDestination[];
}
export interface SingleProductQueryParams {
  productId: string | undefined;
}

export interface UpdateProductQueryParams {
  productId: string | undefined;
  body: any;
  isNewProduct?: boolean;
}

interface CreateProductVariantSKUBody {
  retailPrice: number;
  catalogSKUId: number;
}

export interface CreateProduct {
  catalogProductId: string;
  printProvider: string;
  variants: {
    productColorID: number;
    skus: CreateProductVariantSKUBody;
  };
  storeId: number;
  mockupFile: File;
  frontDesign: File;
  editorData: object;
}
export enum Status {
  Draft = 'Draft',
  Published = 'Published',
  Hidden = 'Hidden',
  UnpublishedChanges = 'Unpublished changes',
  Error = 'Error',
  Unpublished = 'Unpublished',
  UploadingImages = 'Uploading Images',
  LISTING_ERROR = 'Listing Error',
}
export enum StatusColorCodes {
  Ash = '#CCCCCC',
  Green = '#75B981',
  LightRed = '#F1706C',
  Yellow = '#FFC738',
  Red = '#e65200',
}

export interface ProductsFilters {
  search: string;
  status: string;
  sortBy: SortFilter;
}

export interface DeleteProductQueryParams {
  productId: string | undefined;
}

export interface PublishRespones {
  data: ResultsArray;
}

export interface PublishProductQueryParams {
  productIds: string[];
  retryCount?: number;
}

export interface DataForCoord {
  originalHeight: number;
  originalWidth: number;
  boxOriginalHeight: number;
  boxOriginalWidth: number;
  x: number;
  y: number;
  backBoxOriginalHeight: number;
  backBoxOriginalWidth: number;
  backX: number;
  backY: number;
}

export interface TabletProps {
  isTablet: boolean;
}

export interface MyLibraryResponseQueryParams {
  page?: number;
  limit?: number;
  search?: any;
  folderName?: string;
}

export interface UndoRedoConfig {
  [key: string]: any;
  specificDesign: UndoRedoForSpecificDesign;
}

interface UndoRedoForSpecificDesign {
  [id: string]: {
    [key: string]: any;
  };
}

export interface SyncingOptions {
  syncProductTitle: boolean;
  syncDescription: boolean;
  syncVariants: boolean;
  syncMockups: boolean;
  syncShippingProfiles: boolean;
}

export type PaginatedResponse<T> = {
  page: number;
  results: Array<T>;
  totalPages: number;
  totalResults: number;
  limit: number;
};

export interface DataToGenerateAngleImage {
  imageData: Blob | null;
  catalogData: CatalogProductInfo | undefined;
  variant: VariantsV2 | undefined;
  pixiApp?: Application<ICanvas>;
  angle: string;
}
