import { nanoid } from '@reduxjs/toolkit';
import * as PIXI from 'pixi.js';

export function replaceUrl(url: any, reqWidth: any, reqHeight: any) {
  return url
    .replace(
      'mockup-s3.s3.us-west-1.amazonaws.com',
      'd3cgnm60ulh808.cloudfront.net',
    )
    .replace(/([^/]+)$/, `${reqWidth}x${reqHeight}-$1`);
}

async function createTextureFromBlobUrl(
  blobURL: string,
  cacheId: string,
  reqWidth?: string | number,
  reqHeight?: string | number,
): Promise<PIXI.Texture> {
  let texture = PIXI.utils.TextureCache[cacheId];

  if (!texture || !texture?.valid) {
    if (
      blobURL.includes('https://mockup-s3.s3.us-west-1.amazonaws.com') &&
      !cacheId.includes('design_')
    ) {
      blobURL = replaceUrl(blobURL, reqWidth, reqHeight);
    }
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.src = blobURL;

    return new Promise((resolve, reject) => {
      image.onload = () => {
        texture = PIXI.Texture.from(image);
        PIXI.utils.TextureCache[cacheId] = texture;
        resolve(texture);
      };
      image.onerror = (error) => {
        console.error('Error loading image:', error);
        reject(error);
      };
    });
  }
  return texture;
}

async function setupPixi(
  tshirtUrl: string,
  alphaUrl: string,
  designUrl: string | null,
  boundaryWidth: any,
  boundaryHeight: any,
  boundaryX: number | any,
  boundaryY: number | any,
  id: number | string,
  curves_difficulty: string,
  colorAlphaTint: string | undefined,
  app: PIXI.Application<PIXI.ICanvas> | undefined,
  orgWidth: number | any,
  orgHeight: number | any,
  reqWidth: number | string | undefined,
  reqHeight: number | string | undefined,
  sideOfMockup?: string,
): Promise<HTMLImageElement> {
  let image: HTMLImageElement;
  const uniqueId = nanoid(5);

  const Sprite = PIXI.Sprite;

  const tshirtTexture = await createTextureFromBlobUrl(
    tshirtUrl,
    `tshirt_${id}`,
    reqWidth,
    reqHeight,
  );

  const alphaTexture = await createTextureFromBlobUrl(
    alphaUrl,
    `alpha_${id}`,
    reqWidth,
    reqHeight,
  );

  const colorAlphaTexture = await createTextureFromBlobUrl(
    alphaUrl,
    `colorAlpha_${id}`,
    reqWidth,
    reqHeight,
  );

  const designTexture = designUrl
    ? await createTextureFromBlobUrl(
        designUrl,
        `design_${sideOfMockup ?? uniqueId}`,
        reqWidth,
        reqHeight,
      )
    : null;

  const promise: Promise<HTMLImageElement> = new Promise(async (reslove) => {
    image = await setup(app as PIXI.Application<PIXI.ICanvas>);
    reslove(image);
  });

  async function setup(app: PIXI.Application<PIXI.ICanvas>) {
    let designImg: PIXI.Sprite | null = null;

    const tshirtImg = new Sprite(tshirtTexture);
    const alphaImg = new Sprite(alphaTexture);
    const tempAlpha = new Sprite(colorAlphaTexture);
    const colorAlpha = new Sprite(colorAlphaTexture);

    app.renderer.resize(tshirtImg.width, tshirtImg.height);

    if (designTexture) {
      designImg = new Sprite(designTexture);
    }

    const scaleX = app.view.width / (orgWidth ?? 4000);
    const scaleY = app.view.height / (orgHeight ?? 4000);
    const scale = Math.min(scaleX, scaleY);

    designImg?.scale.set(scale);
    const displacementFilter = new PIXI.DisplacementFilter(alphaImg);
    if (curves_difficulty === 'low') {
      displacementFilter.scale.x = -35;
      displacementFilter.scale.y = -15;
    }
    if (curves_difficulty === 'mid') {
      displacementFilter.scale.x = -35;
      displacementFilter.scale.y = -15;
    }
    if (curves_difficulty === 'high') {
      displacementFilter.scale.x = -35;
      displacementFilter.scale.y = -15;
    }

    // Keeping it commented as, I am experimenting few stuffs
    // designImg.filters = [displacementFilter];

    if (designImg) {
      designImg.width = boundaryWidth * scaleX;
      designImg.height = boundaryHeight * scaleY;

      designImg.x = boundaryX * scaleX;
      designImg.y = boundaryY * scaleY;
      // designImg.alpha = 1;
      designImg.blendMode = PIXI.BLEND_MODES.NORMAL;
      designImg.texture.baseTexture.scaleMode = PIXI.SCALE_MODES.NEAREST;
      if (colorAlphaTint) designImg.mask = alphaImg;
      designImg.zIndex = 9999;
    }
    app.stage.addChild(tshirtImg);

    tempAlpha.alpha = 0;
    colorAlpha.alpha = 1;
    /** color will be applied only for mockups */
    if (colorAlphaTint) {
      alphaImg.tint = 0xffffff;
      tempAlpha.tint = 0xffffff;
      colorAlpha.tint = colorAlphaTint;
    }
    app.stage.addChild(colorAlpha);
    app.stage.addChild(tempAlpha);
    // app.stage.addChild(alphaImg);
    if (designImg) app.stage.addChild(designImg);

    // creating a white rectangle to cover the entire stage
    const whiteBackground = new PIXI.Graphics();
    whiteBackground.beginFill(0xffffff);
    whiteBackground.drawRect(0, 0, app.view.width, app.view.height);
    whiteBackground.endFill();

    // adding it as the first child
    app.stage.addChildAt(whiteBackground, 0);

    app.renderer.render(app.stage);

    // making sure that app is ready for extraction and don't remove this
    await app.renderer.extract.image(app.stage);

    /** generating mockups with white background */
    image = await app.renderer.extract.image(app.stage);

    /** keeping below code commented for future reference; */
    /* to generate transparent images */

    //   await app.renderer.extract
    //     .image(app.stage, 'image/jpg', 1)
    //     .then((res: any) => {
    //       image = res;
    //     });

    // Keeping it commented as, I am experimenting few stuffs

    // await PIXI.Texture.removeFromCache(`tshirt_${id}`);
    // await PIXI.Texture.removeFromCache(`tshirt_${id}`);
    // await PIXI.Texture.removeFromCache(`tempAlpha_${id}`);
    // await PIXI.Texture.removeFromCache(`colorAlpha_${id}`);
    // await PIXI.Texture.removeFromCache(`design_${id}`);
    // await PIXI.Texture.removeFromCache(`alpha_${id}`);
    // await PIXI.utils.clearTextureCache(); // clear all
    if (designTexture) {
      designTexture.destroy(true);
    }
    await app.stage.removeChildren();
    return image;
  }

  return promise.then((value) => value);
}

export default setupPixi;

export const clearPixiTextureCache = () => {
  Object.keys(PIXI.utils.TextureCache).forEach((cacheId) => {
    const texture = PIXI.utils.TextureCache[cacheId];
    if (texture) texture?.destroy();
  });
};
