import React from 'react';
import styled from 'styled-components';
import { LoaderGif } from '../../assets/icons';
import { getUtilityData } from '../../store/slices/utilitySlice';
import { useAppSelector } from '../../store/hooks';

interface Props {
  disableBackDrop?: boolean;
}
const Loader: React.FC<Props> = ({ disableBackDrop }) => {
  const { isSidebarExpanded } = useAppSelector(getUtilityData);

  return (
    <Popup
      disableBackDrop={disableBackDrop}
      isSidebarExpanded={isSidebarExpanded}
    >
      <div className="overlay" />
      <Spinner>
        <img height={100} src={LoaderGif} alt="loader" />
      </Spinner>
    </Popup>
  );
};

export default Loader;

const Popup = styled.div<{
  disableBackDrop?: boolean;
  isSidebarExpanded: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  .overlay {
    background-color: ${(prop) =>
      prop.disableBackDrop ? 'transparent' : ' rgba(0, 0, 0, 0.7)'};
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
  }
`;

const Spinner = styled.div`
  width: 250px;
  height: 250px;
  z-index: 99999;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -50px;
`;
