// SampleOrderButton.tsx

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { resetState } from '../../store/slices/sampleOrderSlice';
import amplitude from 'amplitude-js';
import { useLocation, useNavigate } from 'react-router-dom';
import Button from './button';
import SampleOrderModal from '../sample-order';
import SuccessSampleOrderModal from '../sample-order/successSampleOrderModal';
import ConfirmationModal from './confirmation-modal';
import { getUserData } from '../../store/slices/authSlice';
import { useAppSelector } from '../../store/hooks';
import { routeNames } from '../../constants/routesPath';

const SampleOrderButton = () => {
  const navigate = useNavigate();
  const { userInfo } = useAppSelector(getUserData);
  const [showSampleOrder, setShowSampleOrder] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [showSuccessSampleOrder, setShowSuccessSampleOrder] =
    useState<boolean>(false);
  const [generatePayentMethodId, setGeneratePaymentMethodId] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const { pathname } = useLocation();
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  useEffect(() => {
    if (pathname.includes('orders')) {
      setIsPageLoaded(true);
    }
  }, [pathname]);

  useEffect(() => {
    const modalFromCatalog = localStorage.getItem('modalFromCatalog');
    if (modalFromCatalog && isPageLoaded) {
      setShowSampleOrder(true);
      if (modalFromCatalog) {
        /** the amplitude is lossing the context when its navigating from catlog to orders page because of that I have reinitialized the amplitude */
        amplitude
          .getInstance()
          .init(
            process.env.REACT_APP_AMPLITUDE_KEY as string,
            userInfo.everbeeProdUserId,
          );
        amplitude
          .getInstance()
          .logEvent('Sample Order - Initiated', { from: pathname });
      }
    }
  }, [isPageLoaded, pathname, userInfo.everbeeProdUserId]);
  const handleSampleModalClose = () => {
    setGeneratePaymentMethodId(false);
    setShowSampleOrder(false);
    dispatch(resetState());
    setShowCancelModal(false);
    localStorage.removeItem('modalFromCatalog');
  };

  const handleButtonClick = () => {
    if (
      !pathname.includes(routeNames.myProducts) &&
      !pathname.includes(routeNames.orders) &&
      !pathname.includes(routeNames.myLibrary)
    ) {
      navigate(routeNames.orders);
      localStorage.setItem('modalFromCatalog', 'true');
    } else {
      setShowSampleOrder(true);
      amplitude
        .getInstance()
        .logEvent('Sample Order - Initiated', { from: pathname });
    }
  };

  return (
    <div>
      <Button
        label="Create Sample order"
        onClick={handleButtonClick}
        appearance="primary"
        size="large"
        className="sample-btn-catalog"
      />
      {showSampleOrder && (
        <SampleOrderModal
          onClose={() => {
            localStorage.removeItem('modalFromCatalog');
            setShowSampleOrder(false);
          }}
          setShowSuccessSampleOrder={setShowSuccessSampleOrder}
          setShowCancelModal={setShowCancelModal}
          generatePayentMethodId={generatePayentMethodId}
          setGeneratePaymentMethodId={setGeneratePaymentMethodId}
        />
      )}
      {showSuccessSampleOrder && (
        <SuccessSampleOrderModal
          onClose={() => setShowSuccessSampleOrder(false)}
        />
      )}
      {showCancelModal && (
        <div className="sample-cancel-modal">
          <ConfirmationModal
            onClose={() => setShowCancelModal(false)}
            text={'All your progress will be lost'}
            onClick={handleSampleModalClose}
            headingText={'Warning'}
            buttonLabel={'Proceed'}
            buttonColor={'primary'}
          />
        </div>
      )}
    </div>
  );
};

export default SampleOrderButton;
