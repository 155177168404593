import React, { useEffect, useState } from 'react';
import Modal from '../common/modal';
import { useAppSelector } from '../../store/hooks';
import { FlexBox } from '../common/wrappers';
import styled from 'styled-components';
import Topbar from './topbar';
import Cart from './cart';
import Footer from './footer';
import {
  getSampleOrderData,
  resetState,
} from '../../store/slices/sampleOrderSlice';
import Typography from '../typography';

import { useDispatch } from 'react-redux';
import { colors } from '../../assets/colors';
import ProductSelectionStep from './ProductSelectionStep';
import ShippingDetailsStep from './ShippingDetailsStep';
import PaymentStep from './PaymentStep';
import { CreateListingIcon } from '../../assets/icons';
import Button from '../common/button';
import { useGetSampleOrderProductsQuery } from '../../store/services/products';
import { useDebounce } from '../../hooks/useDebounce';
import SmallLoader from '../common/small-loader';
import { routeNames } from '../../constants/routesPath';
import { SampleOrderProduct } from '../../interface/sample-order-interface-v2';
import { useNavigate } from 'react-router-dom';

interface SampleOrderModalProps {
  onClose: () => void;
  setShowSuccessSampleOrder: (value: boolean) => void;
  setShowCancelModal: React.Dispatch<React.SetStateAction<boolean>>;
  setGeneratePaymentMethodId: React.Dispatch<React.SetStateAction<boolean>>;
  generatePayentMethodId: boolean;
}

const SampleOrderModal: React.FC<SampleOrderModalProps> = ({
  onClose,
  setShowSuccessSampleOrder,
  setShowCancelModal,
  generatePayentMethodId,
  setGeneratePaymentMethodId,
}) => {
  const navigate = useNavigate();
  const { currentStep, sampleOrderPaymentId } =
    useAppSelector(getSampleOrderData);
  const [productsData, setProductsData] = useState<SampleOrderProduct[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFulfillMentButtonDisabled, setIsFulfillmentButtonDisabled] =
    useState(sampleOrderPaymentId !== null ? false : true);
  const [isShippingPageValid, setIsShippingPageValid] = useState(true);
  // const [generatePayentMethodId, setGeneratePaymentMethodId] = useState(false);
  const [page, setPage] = useState(1);
  const [searchText, setSearchText] = useState<string | undefined>();
  const [paymentError, setPaymentError] = useState<string>('');
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const debouncedText = useDebounce(searchText);
  const [recalculateShipping, setRecalculateShipping] = useState(false);

  const dispatch = useDispatch();

  // const [showCancelModal, setShowCancelModal] = useState(false);
  const { data: myProducts, isFetching } = useGetSampleOrderProductsQuery({
    page: page,
    search: debouncedText,
    sampleOrder: true,
    // status: 'Published',
  });

  const handleSampleModalClose = () => {
    setGeneratePaymentMethodId(false);
    onClose();
    dispatch(resetState());
    setShowCancelModal(false);
  };

  useEffect(() => {
    if (myProducts?.results && !isFetching) {
      setProductsData((prevProductsData) => {
        return page !== 1
          ? [...prevProductsData, ...myProducts.results]
          : myProducts.results;
      });
      setIsDataLoaded(true);
    }
  }, [isFetching, myProducts, page, setProductsData]);

  useEffect(() => {
    setIsFulfillmentButtonDisabled(
      sampleOrderPaymentId !== null ? false : true,
    );
  }, [sampleOrderPaymentId]);

  const handleCancelModal = () => {
    setShowCancelModal(true);
  };
  return (
    <StyledWrapper
      isEmpty={
        myProducts?.results.length === 0 &&
        productsData.length === 0 &&
        debouncedText === undefined
      }
    >
      <Modal
        title={
          productsData.length > 0 || debouncedText !== undefined ? (
            <Topbar currentStep={currentStep} />
          ) : (
            ''
          )
        }
        size="large"
        onClose={currentStep === 1 ? handleSampleModalClose : handleCancelModal}
        className="sample-order-modal"
      >
        <FlexBox
          direction="column"
          gap="1rem"
          className={`main-section ${isLoading ? 'disabled' : ''}`}
        >
          {isFetching && <SmallLoader />}
          <>
            {isLoading && <SmallLoader />}
            {myProducts?.results.length === 0 &&
            productsData.length === 0 &&
            !isFetching &&
            debouncedText === undefined ? (
              <FlexBox alignItems="center" direction="column">
                <img src={CreateListingIcon} alt="ww" />
                <Typography
                  fontSize="14px"
                  text="Create a listing to be able to order a sample"
                />
                <Button
                  label="Go to Product Catalog"
                  appearance={'primary'}
                  onClick={() => navigate(routeNames.catalog)}
                />
              </FlexBox>
            ) : (
              myProducts?.results &&
              isDataLoaded && (
                <>
                  <FlexBox className="sample-order-body" gap="1rem">
                    <div
                      className="show-no-scrollbar"
                      style={{
                        width: '53%',
                        maxHeight: '600px',
                        minHeight:
                          debouncedText !== undefined ? '500px' : '300px',
                      }}
                    >
                      {currentStep === 1 && (
                        <ProductSelectionStep
                          productsData={productsData}
                          searchText={searchText}
                          setSearchText={setSearchText}
                          page={page}
                          totalPages={myProducts && myProducts.totalPages}
                          setPage={setPage}
                        />
                      )}
                      {currentStep === 2 && (
                        <ShippingDetailsStep
                          setRecalculateShipping={setRecalculateShipping}
                          setIsShippingPageValid={setIsShippingPageValid}
                        />
                      )}
                      {currentStep === 3 && (
                        <PaymentStep
                          generatePayentMethodId={generatePayentMethodId}
                          setIsFulfillmentButtonDisabled={(status: boolean) =>
                            setIsFulfillmentButtonDisabled(status)
                          }
                          errorMessage={paymentError}
                          setPaymentError={setPaymentError}
                        />
                      )}
                    </div>
                    <Cart
                      recalculateShipping={recalculateShipping}
                      setRecalculateShipping={setRecalculateShipping}
                    />
                  </FlexBox>
                  <Footer
                    onClose={handleSampleModalClose}
                    setShowSuccessSampleOrder={setShowSuccessSampleOrder}
                    isFulfillMentButtonDisabled={isFulfillMentButtonDisabled}
                    setGeneratePaymentMethodId={(status) =>
                      currentStep === 3
                        ? setGeneratePaymentMethodId(status)
                        : undefined
                    }
                    generatePaymentMethodId={generatePayentMethodId}
                    setPaymentError={setPaymentError}
                    setIsLoading={setIsLoading}
                    isShippingPageValid={isShippingPageValid}
                  />
                </>
              )
            )}
          </>
        </FlexBox>
      </Modal>
    </StyledWrapper>
  );
};

export default SampleOrderModal;

const StyledWrapper = styled.div<{ isEmpty: boolean }>`
  .sample-order-modal {
    max-width: ${(props) => (props.isEmpty ? '400px' : '1000px')};
    max-height: ${(props) => (props.isEmpty ? '280px' : 'fit-content')};
    height: fit-content;
    padding: 20px 20px 0 20px;
  }
  .react-international-phone-country-selector-dropdown {
    max-width: 200px;
  }
  .react-international-phone-input {
    padding: 6px 12px;
  }

  .main-section {
    margin-top: 1rem;
    min-height: ${(props) => (props.isEmpty ? '270px' : '370px')};
    position: relative;
    &.disabled {
      pointer-events: none;
    }
    .mockup-loader {
      position: absolute;
      top: 50%;
      left: 50%;
      right: 50%;
      bottom: 50%;
    }
  }
  .products-list {
    height: 530px;
  }
  .sample-order-body {
    height: inherit;
    .main-section {
      overflow: scroll;
      width: 50%;
      border: 1px solid #fafafa;
    }
  }
  .more-products {
    text-align: center;
    text-decoration: underline;
    color: ${colors.blue[900]};
  }
  .secondStep {
    .title {
      padding: 10px;
    }
    input {
      margin: 20px;
    }
  }
  .address-wrapper {
    padding: 20px 10px;
    margin-bottom: 10px;
    span {
      font-size: 14px;
    }
  }
  .red-border {
    border: 1px solid ${colors.red[900]};
  }

  .recipient-wrapper {
    margin-top: 10px;
    padding-top: 5px;
  }
  .shipping-wrapper {
    margin-top: 10px;
    padding-top: 5px;
  }
  .error-message {
    color: ${colors.red[900]} !important;
    font-size: 0.8rem;
    font-weight: 700;
    padding: 6px;
  }
  .card-wrapper {
    margin-top: 12px;
    padding: 10px;
  }
`;
