export const SIZE_CHARTS = [
  {
    id: 'sizeChartForBellaCanvas3001',
    imageUrl:
      'https://everbee-pod.s3.us-west-1.amazonaws.com/uploads/sizeChartForBellaCanvas3001.png',
    name: 'sizeChartForBellaCanvas3001',
    userId: 'sizeChartForBellaCanvas3001',
    folderName: [''],
    currentFolder: '',
  },
  {
    id: 'bella-canvas-3501',
    imageUrl:
      'https://everbee-pod.s3.us-west-1.amazonaws.com/uploads/bella-canvas-3501-size-chart.png',
    name: 'lla-canvas-350',
    userId: 'lla-canvas-350',
    folderName: [''],
    currentFolder: '',
  },
  {
    id: 'comfort-colors-C1717',
    imageUrl:
      'https://everbee-pod.s3.us-west-1.amazonaws.com/uploads/comfort-colors-C1717-size-chart.png',
    name: 'comfort-colors-C1717',
    userId: 'comfort-colors-C1717',
    folderName: [''],
    currentFolder: '',
  },
  {
    id: 'gildan-G180',
    imageUrl:
      'https://everbee-pod.s3.us-west-1.amazonaws.com/uploads/G180-sizechart.png',
    name: 'gildan-G180',
    userId: 'gildan-G180',
    folderName: [''],
    currentFolder: '',
  },
];

export const printAreaDimensionsInInchs = {
  adultCrewNeck: { width: 11.41, height: 15.11 },
  adultVNeckFront: { width: 11.41, height: 12.12 },
  adultHoodieFrontAndCropTops: { width: 11.41, height: 11.41 },
  leftChest: { width: 5.61, height: 4.91 },
  youthFrontBack: { width: 10, height: 12 },
  youthHoodieFront: { width: 10, height: 10 },
  drinkware: { width: 9, height: 4 },
  houseware: { width: 3.3, height: 3.3 },
};

export const SORTED_SIZES = ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL'];
