import { FC } from 'react';
import { colors } from '../../../assets/colors';
import { FlexBox } from '../../common/wrappers';
import Typography from '../../typography';
interface Props {
  price: number;
  isGrowth: boolean;
  growthPrice: number;
}
const PriceSection: FC<Props> = ({ isGrowth, price, growthPrice }) => {
  const parsedGrowthPrice = parseFloat(growthPrice.toString()).toFixed(2);
  const parsedNormalPrice = parseFloat(price.toString()).toFixed(2);

  return (
    <>
      <FlexBox
        className="main-price text-qred  font-500 text-[16px]"
        gap="2px"
        alignItems="flex-end"
      >
        <Typography
          primaryColorValue={600}
          fontSize={'16px'}
          text={'From USD'}
        />
        {isGrowth ? (
          <FlexBox>
            <Typography
              primaryColorValue={600}
              fontSize={'16px'}
              style={{ padding: '0 6px' }}
              text={parsedGrowthPrice}
            />
            <p className="discount-price grey500">{parsedNormalPrice}</p>
          </FlexBox>
        ) : (
          parsedNormalPrice
        )}
      </FlexBox>
      {!isGrowth && (
        <span
          className="main-price text-qred  font-500 text-[14px]"
          style={{ color: colors.blue[600] }}
        >
          From USD {parsedGrowthPrice} with EverBee Growth Plan
        </span>
      )}
    </>
  );
};

export default PriceSection;
