import { useNavigate } from 'react-router-dom';
import Button from '../../../common/button';
import Modal from '../../../common/modal';
import { FlexBox } from '../../../common/wrappers';
import Typography from '../../../typography';
import styled from 'styled-components';

interface ConfirmationModalProps {
  onClose: () => void;
}
const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ onClose }) => {
  const navigate = useNavigate();
  return (
    <StyledWrapper>
      <Modal
        size="extraSmall"
        title={'Exit'}
        className="confirmation-modal"
        onClose={onClose}
      >
        <FlexBox direction="column" gap="1rem">
          <FlexBox direction="column" gap="1rem">
            <Typography
              text={`We saved everything you did in drafts`}
              fontSize="16px"
            />
          </FlexBox>

          <FlexBox
            gap="1rem"
            alignItems="center"
            justifyContent="space-between"
            className="buttons-wrapper"
          >
            <FlexBox>
              <Button
                disabled={false}
                label="Great"
                onClick={() => navigate('/')}
                appearance="primary"
                size="fullWidth"
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </Modal>
    </StyledWrapper>
  );
};

export default ConfirmationModal;
const StyledWrapper = styled.div`
  position: absolute;
  .confirmation-modal {
    height: fit-content;
    padding-block: 10px;
    width: 460px !important;
  }
  .buttons-wrapper {
    margin-left: auto;
  }
`;
