import React from 'react';
import styled, { css } from 'styled-components';
import Sidebar from './sidebar';
import MyAccounts from './my-accounts';
import { colors } from '../../assets/colors';
import { useLocation } from 'react-router-dom';
import { routeNames } from '../../constants/routesPath';
import MyFiles from './my-files';
import { TabletProps } from '../../interface';

const UserSettings: React.FC<TabletProps> = ({ isTablet }) => {
  const location = useLocation();
  return (
    <StyledWrapper isMyLibrary={location.pathname === routeNames.myLibrary}>
      {location.pathname !== routeNames.myLibrary && <Sidebar />}
      <div className="main-content">
        {location.pathname === routeNames.userSettings ||
        location.pathname === routeNames.myAccount ? (
          <MyAccounts />
        ) : location.pathname === routeNames.myLibrary ? (
          <MyFiles isInLibrary={false} isTablet={isTablet} />
        ) : (
          <>404</>
        )}
      </div>
    </StyledWrapper>
  );
};

export default UserSettings;

const StyledWrapper = styled.div<{ isMyLibrary: boolean }>`
  display: grid;
  gap: 22px;
  height: fit-content;
  background-color: ${colors.background};
  .main-content {
    height: fit-content;
  }
  ${(props) =>
    !props.isMyLibrary &&
    css`
      grid-template-columns: 30% 1fr;
      padding: 10px 2rem;
    `}
`;
