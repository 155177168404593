import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ProductView from '../common/product-view';
import Breadcrumb from '../common/breadcrumb';
import Layout from '../../layout';
import { useGetSingleCatalogProductQuery } from '../../../store/services/catalog';
import Loader from '../../common/loader';
import { FlexBox } from '../../common/wrappers';
import ProductCardStyleOne from '../cards/product-card-style-one';
import { CatalogItemProperties } from '../../../interface/catalog-interfaces';
import SampleOrderButton from '../../common/sample-order-button';

const SingleProduct: React.FC = () => {
  const { catalogProductId } = useParams();

  const {
    data: productData,
    isLoading,
    isSuccess,
  } = useGetSingleCatalogProductQuery({
    productId: catalogProductId,
  });

  const [isDragging, setIsDragging] = useState(false);

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (isDragging) {
      event.preventDefault();
    }
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Layout childrenClasses="md:pt-[15px] pb-[60px]">
      {isLoading && <Loader />}
      <div className="single-product-wrapper w-full relative">
        {!isLoading && productData && isSuccess && (
          <>
            <div className="product-view-main-wrapper bg-white pt-[30px] md:pt-[12px] w-full">
              <div className="breadcrumb-wrapper w-full text-xs">
                <FlexBox
                  className="container-x mx-auto"
                  justifyContent="space-between"
                  alignItems="baseline"
                >
                  <Breadcrumb
                    paths={[
                      { name: 'Catalog', path: '/' },
                      {
                        name: productData?.catalogProductInfo.title,
                        path: `/catalog/${catalogProductId}`,
                      },
                    ]}
                  />
                  <SampleOrderButton />
                </FlexBox>
              </div>
              <div className="w-full bg-white ">
                <div
                  className="container-x mx-auto"
                  onDragEnter={handleDragStart}
                  onDragEnd={handleDragEnd}
                  onClick={handleClick}
                >
                  <ProductView
                    images={productData.catalogProductInfo.images}
                    productDetails={productData?.catalogProductInfo}
                    printProvidersAndVariants={
                      productData.catalogProductInfo.suppliers
                    }
                    colorGrouping={productData.colorGrouping}
                    sizeGrouping={productData.sizeGrouping}
                  />
                </div>
              </div>
            </div>

            <div className="related-product w-full bg-white">
              <div className="container-x mx-auto">
                <div className="w-full py-[60px]">
                  <h1 className="text-2xl  font-600 text-qblacktext leading-none mb-[30px]" style={{fontSize: '24px'}}>
                    Related Products
                  </h1>
                  <div
                    data-aos="fade-up"
                    className="grid xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 xl:gap-[30px] gap-5"
                  >
                    {(productData?.relatedProducts || []).map(
                      (
                        productInfo: {
                          _id: string;
                          title: string;
                          image: string;
                          properties: CatalogItemProperties[];
                          minPrice: number;
                          model: string | undefined;
                          brand: string;
                          minPriceSubscription: number;
                        },
                        index: React.Key | null | undefined,
                      ) => (
                        <ProductCardStyleOne
                          key={index}
                          productId={productInfo._id}
                          productTitle={productInfo.title}
                          productImage={productInfo.image}
                          properties={productInfo.properties}
                          fromPrice={productInfo.minPrice}
                          modalNumber={productInfo.model}
                          brand={productInfo.brand}
                          growthPrice={productInfo.minPriceSubscription}
                        />
                      ),
                    )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default SingleProduct;
