import { isFulfilled, isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { setConnectShopModal } from './slices/utilitySlice';
import {
  setIsProductionPartnerCreated,
  setUserIdAndEmail,
} from './slices/authSlice';

export const errorHandlingMiddleware: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    if (isRejectedWithValue(action)) {
      const redirectUrl = action?.meta?.baseQueryMeta?.response?.url;
      if (action?.payload?.status === 'PARSING_ERROR' && redirectUrl) {
        window.location.href = redirectUrl;
      }
      const errMsg = action?.payload?.data?.message;
      if (errMsg === 'invalid_grant' || errMsg === 'invalid_token') {
        const data = action?.payload?.data?.data;
        if (data) {
          api.dispatch(
            setUserIdAndEmail({ email: data?.email, userId: data?._id }),
          );
          api.dispatch(setConnectShopModal(true));
        }
      } else {
        toast.error(errMsg);
      }
    }
    if (isFulfilled(action)) {
      if (action?.payload?.message) {
        toast.success(action?.payload?.message);
      }

      /** to set production partner banner */
      const isProductionPartnerCreated =
        action?.payload?.data?.isProductionPartnerCreated;
      if (
        isProductionPartnerCreated === false ||
        isProductionPartnerCreated === true
      ) {
        api.dispatch(setIsProductionPartnerCreated(isProductionPartnerCreated));
      }
    }
    return next(action);
  };
