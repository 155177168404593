import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithAuth } from '../baseQuery';
import {
  CardDetailsResponse,
  ManageBillingResponse,
  UserInfoResponse,
} from '../../interface/user-interface';

export const userAPI = createApi({
  reducerPath: 'user',
  baseQuery: baseQueryWithAuth,
  endpoints: (builder) => ({
    getUserInfo: builder.query<UserInfoResponse, void>({
      query: () => `/users/me`,
    }),
    getCardDetails: builder.query<CardDetailsResponse[], void>({
      query: () => `/users/card-details`,
    }),
    stripeManageBillingSession: builder.query<ManageBillingResponse, void>({
      query: () => `/users/stripe-manage-billing-session`,
    }),
    changeShop: builder.mutation<void, { shopId: number }>({
      query: ({ shopId }) => {
        const body = { shopId };
        return {
          url: '/users/change-shop',
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

export const {
  useGetUserInfoQuery,
  useGetCardDetailsQuery,
  useLazyStripeManageBillingSessionQuery,
  useChangeShopMutation,
} = userAPI;
