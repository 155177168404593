import React, { useEffect } from 'react';
import Mockups from '../../components/mockups';
import { TabletProps } from '../../interface';
import { useAppDispatch } from '../../store/hooks';
import { resetMockupSlice } from '../../store/slices/mockupSlice';
const MockupsPage: React.FC<TabletProps> = ({ isTablet }) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    /** resetting the mockups slice when the mockup page is unmounted */
    return () => {
      dispatch(resetMockupSlice());
    };
  }, [dispatch]);
  return <Mockups isTablet={isTablet} />;
};

export default MockupsPage;
