import { Canvas } from 'fabric/fabric-impl';
import { IObject, JSONObject, ObjectTypes } from '../../interface';
import { fabric } from 'fabric';
import { colors } from '../../assets/colors';
import { SingleCatalogProductVariants } from '../../interface/catalog-interfaces';
import setupPixi from '../mockups/mockup-generator';
import _ from 'lodash';
import * as PIXI from 'pixi.js';
import { generateContrastingColor } from '../../helper/generateContrastingColor';
import { applyMugCanvas } from './subComponents/mugs/utils';
import { addCustomImage } from '../../classes/image';
import {
  createCircleOrnamentCanvas,
  createHeartOrnamentCanvas,
  createOvalOrnamentCanvas,
  createStarOrnamentCanvas,
} from './subComponents/ornaments/utils';
import { getCanvasWidthFromTitle } from './subComponents/ornaments';
import {
  MugDimensions,
  MugType,
  ProductName,
  ProductType,
  SingleProductVariant,
} from '../../interface/product-interface';
import {
  Side,
  VariantEditorDataV2,
  VariantsV2,
  CatalogProductInfo,
} from '../../interface/catalog-interface-v2';
import { FileRejection } from 'react-dropzone';
import { toast } from 'react-toastify';
// Cache to store base64 strings of images

export const deleteObj = (
  event: any,
  drawingBoard: Canvas | undefined,
  selectedElementId?: string | string[],
) => {
  event.stopPropagation();
  const allElements = drawingBoard?.getObjects();
  const elementsToDelete =
    typeof selectedElementId === 'string'
      ? [selectedElementId]
      : selectedElementId;

  elementsToDelete?.forEach((elementId) => {
    const elementToDelete = allElements?.find(
      (ele: any) => ele.id === elementId,
    );
    if (elementToDelete) drawingBoard?.remove(elementToDelete);
  });
};

export const duplicateObj = (
  drawingBoard: Canvas | undefined,
  files: JSONObject[],
  isBulkDuplicate: boolean = false,
  disableLoader?: () => void,
) => {
  const activeObjects = isBulkDuplicate
    ? drawingBoard?.getActiveObjects()
    : drawingBoard?.getActiveObject()
    ? [drawingBoard?.getActiveObject()]
    : [];

  const lastImageId = lastAddedElementIDByType(ObjectTypes.image, files);
  const lastTextId = lastAddedElementIDByType(ObjectTypes.text, files);

  activeObjects?.forEach((obj, index) => {
    if (obj) {
      const type = obj.type;
      const nextTextId = lastTextId + (index + 1);
      const nextImageId = lastImageId + (index + 1);
      obj?.clone((clonedObj: IObject) => {
        clonedObj.set({
          left:
            ((obj.group ? (obj.group.left ?? 0) + (obj.left ?? 0) : obj.left) ??
              0) +
            10 +
            (type === ObjectTypes.image
              ? obj.getScaledWidth()
              : obj.width ?? 0) /
              2,
          top:
            ((obj.group ? (obj.group.top ?? 0) + (obj.top ?? 0) : obj.top) ??
              0) +
            10 +
            (type === ObjectTypes.image
              ? obj.getScaledHeight()
              : obj.height ?? 0) /
              2,
          evented: true,
          id:
            type === ObjectTypes.image
              ? 'image-' + nextImageId
              : 'text-' + nextTextId,
          name: type === ObjectTypes.image ? clonedObj.name : '',
        });
        if (
          drawingBoard?.getObjects().find((i: any) => i.id === clonedObj.id)
        ) {
          return;
        }
        drawingBoard?.add(clonedObj);
        clonedObj.setCoords();
        drawingBoard?.requestRenderAll();
        if (disableLoader) disableLoader();
      });
    }
  });
};

export const lastAddedElementIDByType = (
  type: string | undefined,
  files: JSONObject[],
) => {
  const allFilesByType = files.filter((file) => file.type === type);
  if (allFilesByType.length === 0) {
    return 0;
  }
  const allIds = allFilesByType.map((file) => file.id);
  const addedIds = [];
  for (let id of allIds) {
    const splitted = id?.split('-');
    if (splitted) addedIds.push(parseInt(splitted[1]));
  }
  return Math.max.apply(null, addedIds);
};

const addCenterGridLines = (
  canvas: Canvas,
  currentEditor: string,
  coords?: { height: number; width: number; left: number; top: number },
) => {
  const boxHeight = canvas.getHeight();
  const boxWidth = canvas.getWidth();

  const height = coords?.height ?? 0;
  const width = coords?.width ?? 0;
  const left = coords?.left ?? 0;
  const top = coords?.top ?? 0;

  const defaultVerticalCoords = [
    left + width / 2,
    top,
    left + width / 2,
    height + top,
  ];

  const mugsVerticalCoords = [boxWidth / 2, 0, boxWidth / 2, boxHeight];
  const defaultHorizontalCoords = [
    left,
    top + height / 2,
    left + width,
    height / 2 + top,
  ];
  const mugsHorizontalCoords = [0, boxHeight / 2, boxWidth, boxHeight / 2];
  const verticalLine = new fabric.Line(
    currentEditor !== ProductType.ApparelHeavy &&
    currentEditor !== ProductType.ApparelLight
      ? mugsVerticalCoords
      : defaultVerticalCoords,
    {
      stroke: 'red',
      strokeWidth: 1,
      selectable: false,
      evented: false,
      hoverCursor: 'default',
      name: 'verticalLine',
      visible: false,
    },
  );

  const horizonatalLine = new fabric.Line(
    currentEditor !== ProductType.ApparelHeavy &&
    currentEditor !== ProductType.ApparelLight
      ? mugsHorizontalCoords
      : defaultHorizontalCoords,
    {
      stroke: 'red',
      strokeWidth: 1,
      selectable: false,
      evented: false,
      hoverCursor: 'default',
      name: 'horizontalLine',
      visible: false,
    },
  );

  canvas.add(verticalLine);
  canvas.add(horizonatalLine);
  if (currentEditor === ProductType.Mug && canvas.width) {
    const lineOptions = {
      strokeWidth: 1,
      stroke: '#6FB4F7',
      selectable: false,
      evented: false,
      name: 'guideLines',
      strokeDashArray: [5, 5],
      excludeFromExport: true,
    };
    const positions = [
      canvas?.width * 0.2,
      canvas?.width * 0.5,
      canvas?.width * 0.8,
    ];

    positions.forEach((x) => {
      const line = new fabric.Line([x, 0, x, 350], lineOptions);
      canvas.add(line);
      line.bringToFront();
    });
    canvas.requestRenderAll();
  }
};
const defaultClippingCanvas = (
  canvas: Canvas,
  boundaryDiv: HTMLElement,
  coords: { height: number; width: number; left: number; top: number },
) => {
  const { height, width, left, top } = coords;
  boundaryDiv.className = 'dashed-border';
  boundaryDiv.style.top = `${top ?? 0}px`;
  boundaryDiv.style.left = `${left ?? 0}px`;
  boundaryDiv.style.width = `${width}px`;
  boundaryDiv.style.height = `${height}px`;
  let artBoard: any = new fabric.Rect({
    stroke: colors.grey[700],
    fill: 'black',
    width,
    height,
    selectable: false,
    hoverCursor: 'default',
    evented: false,
    opacity: 1,
    name: 'boundary-rect',
    top,
    left,
  });
  canvas.clipPath = artBoard;
  canvas.remove(artBoard);
  canvas.requestRenderAll();
};
export const applyClippingToCanvas = (
  canvas: Canvas,
  data: CatalogProductInfo | undefined,
  updatedVariant?: SingleCatalogProductVariants,
  angle?: string,
  color?: string,
) => {
  if (!data) return;
  const boundaryDiv = document.createElement('div');
  boundaryDiv.style.position = 'absolute';
  switch (data.editorType) {
    case ProductType.Mug:
      addCenterGridLines(canvas, data.editorType);
      applyMugCanvas(canvas, data, boundaryDiv);
      break;
    case ProductType.Ornament:
      addCenterGridLines(canvas, data.editorType);
      if (data?.editorSubType)
        if (data?.editorSubType === ProductName.CircleOrnament) {
          createCircleOrnamentCanvas(canvas, color);
        } else if (data?.editorSubType === ProductName.OvalOrnament) {
          createOvalOrnamentCanvas(canvas, color);
        } else if (data?.editorSubType === ProductName.StarOrnament) {
          createStarOrnamentCanvas(canvas, color);
        } else if (data?.editorSubType === ProductName.HeartOrnament) {
          createHeartOrnamentCanvas(canvas, color);
        }
      break;
    default:
      const { height, width, left, top } = getImageBoxCoords(data, angle);
      addCenterGridLines(canvas, data.type, { height, width, left, top });
      defaultClippingCanvas(canvas, boundaryDiv, { height, width, left, top });
      break;
  }
  const lightColor = generateContrastingColor('#' + updatedVariant?.colorHex);
  boundaryDiv.style.borderColor = lightColor;

  if (!document.getElementsByClassName('dashed-border')[0]) {
    document
      .getElementsByClassName('canvas-container')[0]
      ?.prepend(boundaryDiv);
  }
  canvas.requestRenderAll();
};

export function flipVertically(drawingBoard: Canvas | undefined) {
  const element = drawingBoard?.getActiveObject();
  element?.set('flipX', !element?.get('flipX'));
  drawingBoard?.getActiveObject()?.setCoords();
  drawingBoard?.renderAll();
  drawingBoard?.fire('object:modified', { target: element });
}

export function flipHorizontally(drawingBoard: Canvas | undefined) {
  const element = drawingBoard?.getActiveObject();
  element?.set('flipY', !element?.get('flipY'));
  drawingBoard?.getActiveObject()?.setCoords();
  drawingBoard?.renderAll();
  drawingBoard?.fire('object:modified', { target: element });
}

export function getScalePercentage(scale: number | undefined) {
  if (scale === undefined) {
    return scale;
  }
  return scale * 100;
}

export const onlyNumberWIthDecimalRegex = new RegExp(/^\d*\.?(?:\d{1,2})?$/);

export function isOnlyPersonalization(objects: any[]) {
  const res = objects.some((obj) => obj.personalizationData !== undefined);
  return res && objects.length > 0;
}

export function convertToNumber(str: string) {
  if (/\./.test(str)) {
    return parseFloat(str); // Convert to float
  } else {
    return parseInt(str, 10); // Convert to integer
  }
}

export const generateRandomColors = (count: number) => {
  const colors = [];
  for (let i = 0; i < count; i++) {
    let randomColor = Math.floor(Math.random() * 16777215).toString(16);
    if (colors.indexOf('#' + randomColor) >= 0) {
      randomColor = Math.floor(Math.random() * 16777215).toString(16);
    }
    colors.push('#' + randomColor);
  }
  return colors;
};
export const colorsPallete = [
  { colorCode: '#ffffff', color: 'White' },
  { colorCode: '#b6b5b5', color: 'Light Grey' },
  { colorCode: '#98989a', color: 'Grey' },
  { colorCode: '#646a6a', color: 'Dark Grey' },
  { colorCode: '#000000', color: 'Black' },
  { colorCode: '#eb1d26', color: 'Red' },
  { colorCode: '#ca1028', color: 'Dark Red' },
  { colorCode: '#643b13', color: 'Brown' },
  { colorCode: '#be8042', color: 'Light Brown' },
  { colorCode: '#f88033', color: 'Crusta' },
  { colorCode: '#f16220', color: 'Orange' },
  { colorCode: '#f4bc17', color: 'Yellow' },
  { colorCode: '#6ea748', color: 'Green' },
  { colorCode: '#057944', color: 'Dark Green' },
  { colorCode: '#2ba5c7', color: 'Turquoise' },
  { colorCode: '#73aee3', color: 'Light Blue' },
  { colorCode: '#0c3f8b', color: 'Dark Blue' },
  { colorCode: '#8e69b0', color: 'Purple' },
  { colorCode: '#502781', color: 'Purple' },
  { colorCode: '#d173a7', color: 'Light Pink' },
  { colorCode: '#c42a86', color: 'Pink' },
];

const getCanvasDimensions = (productInfo: any) => {
  switch (productInfo.editorType) {
    case ProductType.Mug:
      const height =
        productInfo.model === MugType._15oz
          ? MugDimensions._15OZ
          : MugDimensions._11OZ;
      return {
        width: 700,
        height: height,
      };

    case ProductType.Ornament:
      const width = getCanvasWidthFromTitle(productInfo.title);
      return { width, height: 500 };

    default:
      return {
        width: 500,
        height: 550,
      };
  }
};

export const initializeFabricWithNewCanvas = (
  catalogData: any,
  initializeCanvasWithJson: any,
  json: any,
  loadMockupFromApi: boolean = false,
  updatedVariant?: VariantsV2,
  updateUndoRedoState?: () => void,
  angle?: string,
  angleChange?: boolean,
  color?: string,
) => {
  const elements = document.getElementsByClassName('canvas-container');
  for (const element of elements) {
    element.remove();
  }
  const { width, height } = getCanvasDimensions(catalogData);
  const containerDiv = document.getElementById('canvas-parent');
  const canvas = document.createElement('canvas');
  canvas.height = height;
  canvas.width = width;
  // canvas.style.display = files.length === 0 ? 'none' : 'block';
  canvas.id = 'drawingArea';
  canvas.className = 'for-extra-flow';
  if (containerDiv) {
    containerDiv.appendChild(canvas);
  }
  const initCanvas: Canvas = new fabric.Canvas('drawingArea', {
    preserveObjectStacking: true,
    centeredScaling: true,
    controlsAboveOverlay: true,
  });
  applyClippingToCanvas(initCanvas, catalogData, undefined, angle, color);

  if (updatedVariant) {
    initializeCanvasWithJson(
      json,
      initCanvas,
      loadMockupFromApi,
      updatedVariant,
      updateUndoRedoState,
      angle,
      angleChange,
      color,
    );
  } else {
    initializeCanvasWithJson(
      json,
      initCanvas,
      false,
      undefined,
      updateUndoRedoState,
      angleChange,
    );
  }
};

export const getCurrentViewImage = (
  drawingBoard: Canvas | undefined,
  catalogData: any,
  angle: string,
  drawingBoardJSON: any,
  currentProductType?: string,
  imagesBase64Data?: Record<string, string>,
) => {
  const { sides } = catalogData?.printAreas[0] || {};
  const side = sides?.find((s: Side) => s.side === angle);
  const { designFileDimensionsInPX } = side || {
    designFileDimensionsInPX: { width: 0, height: 0 },
  };

  return new Promise(async (resolve, reject) => {
    let copyOfBoard = _.cloneDeep(drawingBoard);
    const canvas = new fabric.Canvas(null, {
      height: drawingBoard?.height,
      width: drawingBoard?.width,
    });

    if (drawingBoardJSON !== null) {
      try {
        await loadCanvas(
          canvas,
          drawingBoardJSON === '' ? '{"objects":[]}' : drawingBoardJSON,
          catalogData,
          angle,
          drawingBoard,
          currentProductType,
        );

        copyOfBoard = canvas;
      } catch (error: any) {
        console.log('error', error);
        reject(error);
      }
    }

    if (copyOfBoard) {
      try {
        const viewBox = getViewBox(currentProductType, copyOfBoard);
        let svgString = copyOfBoard.toSVG({
          suppressPreamble: true,
          viewBox,
          width: designFileDimensionsInPX.width,
          height: designFileDimensionsInPX.height,
        });

        if (currentProductType === ProductType.Mug) {
          const whiteBackground = `<rect x="0" y="0" width="${designFileDimensionsInPX.width}" height="${designFileDimensionsInPX.height}" fill="white" />`;
          svgString = svgString.replace('<g ', `<g>${whiteBackground}`);
        }

        // replace Blob URLs with base64
        const updatedSvgString = await convertBlobUrlsToBase64(
          svgString,
          copyOfBoard,
          imagesBase64Data,
        );

        const result = await createImageBlobWithSvg(updatedSvgString);
        resolve(result);
      } catch (error) {
        console.error('Error generating image:', error);
        reject(error);
      }
    }
  });
};
const handleBlobAndSetObject = (
  offscreenCanvas: HTMLCanvasElement,
  img: fabric.Image,
  objCls: any,
  obj: any,
  canvas: fabric.Canvas,
  loadResolve: () => void,
) => {
  offscreenCanvas.toBlob(
    (blob: Blob | null) => {
      if (blob) {
        const blobURL = URL.createObjectURL(blob);
        objCls.setElement(img.getElement());
        objCls.setSrc(blobURL, () => {
          objCls.set({ scaleX: obj.scaleX, scaleY: obj.scaleY });
          objCls.setCoords();
          objCls.dirty = true;
          canvas.renderAll();
          loadResolve();
        });
      } else {
        console.error('Blob is null');
      }
    },
    'image/png',
    1,
  );
};

const loadCanvas = (
  canvas: fabric.Canvas,
  drawingBoardJSON: any,
  catalogData: any,
  angle: string,
  drawingBoard: Canvas | undefined,
  currentProductType?: string,
) => {
  return new Promise<void>((resolve, reject) => {
    const promisesArr: Promise<void>[] = [];

    canvas.loadFromJSON(
      drawingBoardJSON,
      async () => {
        // This function will be called after all objects are loaded
        canvas.renderAll.bind(canvas);
        if (
          currentProductType === ProductType.ApparelHeavy ||
          currentProductType === ProductType.ApparelLight
        ) {
          const { height, width, left, top } = getImageBoxCoords(
            catalogData,
            angle,
          );
          let artBoard: any = new fabric.Rect({
            stroke: colors.grey[700],
            fill: 'black',
            width,
            height,
            selectable: false,
            hoverCursor: 'default',
            evented: false,
            opacity: 1,
            name: 'boundary-rect',
            top,
            left,
          });
          canvas.clipPath = artBoard;
          canvas.remove(artBoard);
          canvas.requestRenderAll();
        } else if (currentProductType === ProductType.Mug) {
          const extraAreaInPXForInactiveFeel = 25;
          const canvasHeight = canvas.getHeight();
          const canvasWidth = canvas.getWidth();
          let artBoard: any = new fabric.Rect({
            left: extraAreaInPXForInactiveFeel - 4,
            top: extraAreaInPXForInactiveFeel - 4,
            width: canvasWidth - extraAreaInPXForInactiveFeel * 2 + 4,
            height: canvasHeight - extraAreaInPXForInactiveFeel * 2 + 4,
            stroke: colors.grey[700],
            fill: 'black',
            selectable: false,
            hoverCursor: 'default',
            evented: false,
            opacity: 1,
            name: 'boundary-rect',
          });
          canvas.clipPath = artBoard;
          canvas.remove(artBoard);
          canvas.requestRenderAll();
        }

        await Promise.all(promisesArr)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            console.error('Error in loadCanvas promises:', error);
            reject(error);
          });
      },
      (obj: any, objCls: any) => {
        if (objCls.type === 'custom-image' && obj.src.startsWith('http')) {
          const imagePromise = new Promise<void>((loadResolve, loadReject) => {
            // Load the image from URL
            fabric.Image.fromURL(
              obj.src,
              async (img) => {
                try {
                  // Set the loaded image to the object
                  const imageElement = img.getElement() as HTMLImageElement;
                  const originalWidth = imageElement.width;
                  const originalHeight = imageElement.height;

                  const aspectRatio = originalWidth / originalHeight;
                  let width = originalWidth;
                  let height = originalHeight;

                  const maxWidth: any = drawingBoard?.width;
                  const maxHeight: any = drawingBoard?.height;

                  if (width > maxWidth) {
                    width = maxWidth;
                    height = width / aspectRatio;
                  }

                  if (height > maxHeight) {
                    height = maxHeight;
                    width = height * aspectRatio;
                  }

                  const offscreenCanvas = document.createElement('canvas');
                  const ctx = offscreenCanvas.getContext('2d')!;
                  offscreenCanvas.width = imageElement.width;
                  offscreenCanvas.height = imageElement.height;

                  ctx.imageSmoothingEnabled = true;
                  ctx.imageSmoothingQuality = 'high';

                  ctx.drawImage(
                    imageElement,
                    drawingBoard?.width as number,
                    drawingBoard?.height as number,
                  );

                  // Call the new function to handle blob and set object properties
                  handleBlobAndSetObject(
                    offscreenCanvas,
                    img,
                    objCls,
                    obj,
                    canvas,
                    loadResolve,
                  );
                } catch (error) {
                  console.error('Error loading image:', error);
                  loadReject(error);
                }
              },
              { crossOrigin: 'anonymous' },
            );
          });
          promisesArr.push(imagePromise);
        }
      },
    );
  });
};

const getViewBox = (
  currentProductType?: string,
  copyOfBoard?: any,
): { x: number; y: number; width: number; height: number } | undefined => {
  let viewBox = undefined;
  if (
    currentProductType === ProductType.ApparelHeavy ||
    currentProductType === ProductType.ApparelLight
  ) {
    const { left, top, width, height } = copyOfBoard?.clipPath || {};
    viewBox = {
      x: left ?? 0,
      y: top ?? 0,
      width: width ?? 0,
      height: height ?? 0,
    };
  } else if (currentProductType === ProductType.Mug) {
    const { left, top, width, height } = copyOfBoard?.clipPath || {};
    viewBox = {
      x: left,
      y: top,
      width: width,
      height: height,
    };
    hideNonImageAndTextObjects(copyOfBoard);
  } else if (currentProductType === ProductType.Ornament) {
    copyOfBoard?.setBackgroundColor('transparent', () => {
      hideNonImageAndTextObjects(copyOfBoard);
      viewBox = undefined;
    });
  }
  return viewBox;
};

const convertBlobUrlsToBase64 = async (
  svgString: string,
  copyOfBoard: Canvas,
  imagesBase64Data?: Record<string, string>,
) => {
  const imageObjects = copyOfBoard?.getObjects(
    ObjectTypes.image,
  ) as fabric.Image[];

  for (let imgObj of imageObjects) {
    const originalBlobURL = (imgObj as any).originalImageBlobSrc;

    const blobUrl = imgObj.getSrc();

    // Check if the base64 string is already in the cache
    let base64Image: string = '';

    if (imagesBase64Data) {
      base64Image = imagesBase64Data[originalBlobURL || blobUrl];
    }

    /** if no base64Image found fetch from original image */
    if (!base64Image) {
      const imageSrc = (imgObj as any)?.originalSrc || (imgObj as any)?.src;

      if (imageSrc && imageSrc.startsWith('http')) {
        const response = await fetch((imgObj as any)?.originalSrc);
        const blob = await response.blob();
        base64Image = await new Promise<string>((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result as string);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      } else if (imageSrc?.startsWith('data:image/png;base64')) {
        base64Image = imageSrc;
      }
    }

    // Replace the blob URL in the SVG with the base64
    svgString = svgString.replace(blobUrl, base64Image);
  }

  return svgString;
};

const hideNonImageAndTextObjects = (canvas: any) => {
  canvas?.forEachObject((obj: any) => {
    if (obj.type !== ObjectTypes.image && obj.type !== ObjectTypes.text) {
      obj.set({ opacity: 0 });
    }
  });
};

const createImageBlobWithSvg = (
  svgString: string,
): Promise<{ blob: Blob; svgBlob: Blob }> => {
  return new Promise((resolve, reject) => {
    const svgElement = new Blob([svgString], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(svgElement);

    const image = new Image();
    image.src = url;

    image.onload = () => {
      const canvas = document.createElement('canvas');
      const context = canvas.getContext('2d')!;
      canvas.width = image.width;
      canvas.height = image.height;

      context.drawImage(image, 0, 0);

      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve({ blob, svgBlob: svgElement });
          } else {
            console.error('Failed to create image blob.');
            reject(new Error('Failed to create image blob.'));
          }
        },
        'image/png',
        1,
      );
    };

    image.onerror = reject;
  });
};

export const compareObj = (obj1: any, obj2: any): boolean => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};
export const getImageBoxCoords = (data: CatalogProductInfo, angle?: string) => {
  let newAngle = angle || 'front';
  const { boundaryBox } = data;
  const y: any = boundaryBox[newAngle + 'Y'];
  const x: any = boundaryBox[newAngle + 'X'];
  let boxOriginalHeight: any = boundaryBox[newAngle + 'BoxOriginalHeight'];
  let boxOriginalWidth: any = boundaryBox[newAngle + 'BoxOriginalWidth'];
  const currentImageWidth = 500;
  const currentImageHeight = 550;
  const widthScaleFactor =
    currentImageWidth / (boundaryBox[newAngle + 'ImageOriginalWidth'] as any);
  const heightScaleFactor =
    currentImageHeight / (boundaryBox[newAngle + 'ImageOriginalHeight'] as any);

  const newBoxWidth = boxOriginalWidth * widthScaleFactor;
  const newBoxHeight = boxOriginalHeight * heightScaleFactor;

  const newX = x * widthScaleFactor;
  const newY = y * heightScaleFactor;
  const box = {
    width: 0,
    height: 0,
    left: 0,
    top: 0,
  };
  box.width = Math.round(newBoxWidth);
  box.height = Math.round(newBoxHeight);
  box.left = Math.round(newX);
  box.top = Math.round(newY);
  return box;
};

export function getMinimumPriceFromVariants(
  variantsData: SingleProductVariant[],
  field: 'cost' | 'mrsp',
) {
  const values = variantsData.map((it) => it[field]);
  const flattened = values.flat();
  return Math.min(...flattened.flat());
}

export const getCurrentAngleJson = (
  variantEditorData: VariantEditorDataV2[],
  selectedVariant: any,
  selectedAngle: string,
  defaultEditorData: any,
) => {
  const index = variantEditorData.findIndex(
    (variant) =>
      variant.id === selectedVariant?.id ||
      selectedVariant.connectedVariants
        .map((a: { id: any }) => a.id)
        .includes(variant.id),
  );
  const currentVariant = variantEditorData[index];
  let jsonData: any = null;
  if (
    currentVariant &&
    (currentVariant.variantSpecificDesign ||
      currentVariant.front !== null ||
      currentVariant.back !== null)
  ) {
    if (selectedAngle === 'front') {
      jsonData = currentVariant.front;
    } else {
      jsonData = currentVariant.back;
    }
  } else {
    jsonData =
      selectedAngle === 'front'
        ? defaultEditorData.front
        : defaultEditorData.back;
  }
  return jsonData;
};

export const getMockupImageForAngle = async (
  base64String: string | null,
  selectedAngle: any,
  data: CatalogProductInfo,
  selectedVariant: VariantsV2,
  pixiApp: PIXI.Application<PIXI.ICanvas>,
  preloadedImages?: { [key: string]: string },
) => {
  // const isHeather = selectedVariant.label
  //   .toLocaleLowerCase()
  //   .includes('heather');
  const previewBoundaryBox = data.previewBoundaryBox;
  const mainImg = selectedVariant.images[selectedAngle];
  let mainImgUrl = mainImg;
  let alphaUrl = mainImg;

  if (data.editorType === ProductType.Ornament) {
    mainImgUrl = previewBoundaryBox[
      'normal' + selectedAngle + 'FullAlphaImageUrl'
    ] as string;
    alphaUrl = previewBoundaryBox[
      'normal' + selectedAngle + 'FullAlphaImageUrl'
    ] as string;
  }
  if (
    preloadedImages &&
    preloadedImages[mainImg] &&
    preloadedImages[alphaUrl]
  ) {
    mainImgUrl = preloadedImages[mainImg];
    alphaUrl = preloadedImages[alphaUrl];
  }

  // if (isHeather) {
  //   alphaUrl =
  //     previewBoundaryBox['heather' + selectedAngle + 'FullAlphaImageUrl'];
  //   mainImageUrl =
  //     previewBoundaryBox['heather' + selectedAngle + 'MainImageUrl'];
  // } else {
  //   alphaUrl =
  //     previewBoundaryBox['normal' + selectedAngle + 'FullAlphaImageUrl'];
  //   mainImageUrl =
  //     previewBoundaryBox['normal' + selectedAngle + 'MainImageUrl'];
  // }

  const img = await setupPixi(
    mainImgUrl,
    alphaUrl,
    base64String,
    previewBoundaryBox[selectedAngle + 'BoundaryWidth'],
    previewBoundaryBox[selectedAngle + 'BoundaryHeight'],
    previewBoundaryBox[selectedAngle + 'BoundaryX'],
    previewBoundaryBox[selectedAngle + 'BoundaryY'],
    `${selectedAngle}_${selectedVariant?.colorHex}`,
    previewBoundaryBox.curvesDifficulty,
    data.editorType === ProductType.Ornament
      ? `#${selectedVariant?.colorHex}`
      : undefined,
    // `#${selectedVariant?.colorHex}`,
    pixiApp,
    previewBoundaryBox[selectedAngle + 'ImageOriginalWidth'],
    previewBoundaryBox[selectedAngle + 'ImageOriginalHeight'],
    400,
    400,
  );
  return img;
};

export const getImageDPI = (
  elementId: string,
  drawingBoard: Canvas | undefined,
  selectedAngle: string,
  printAreaDimensions: Side[] | undefined,
): Promise<number> => {
  return new Promise((resolve, reject) => {
    try {
      const element = drawingBoard
        ?.getObjects()
        .find((obj: any) => obj.id === elementId);
      const clipPath = drawingBoard?.clipPath;

      const clipPathHeight = clipPath ? clipPath.height : drawingBoard?.height;
      const clipPathWidth = clipPath ? clipPath.width : drawingBoard?.width;

      const printAreaDimension = printAreaDimensions?.find(
        (e) => e.side === selectedAngle,
      )?.printAreaDimensionsInInchs;
      if (
        element?.width &&
        element.height &&
        clipPathHeight &&
        clipPathWidth &&
        printAreaDimension
      ) {
        let imageWidth, imageHight;

        /** original height and width of an image */
        const { width, height } = element;

        imageHight = height;
        imageWidth = width;

        const { originalHeight, originalWidth } = element as any;

        if (originalHeight && originalWidth) {
          imageHight = originalHeight;
          imageWidth = originalWidth;
        }

        const { width: printAreaWidth, height: printAreaHeight } =
          printAreaDimension;

        const heightScaleFactor = (printAreaHeight * 96) / clipPathHeight;
        const widthScaleFactor = (printAreaWidth * 96) / clipPathWidth;
        const onePixelInInch = 0.0104166667;

        const dpiX =
          imageWidth /
          (element.getScaledWidth() * widthScaleFactor * onePixelInInch);
        const dpiY =
          imageHight /
          (element.getScaledHeight() * heightScaleFactor * onePixelInInch);

        const dpi = Math.sqrt(dpiX * dpiX + dpiY * dpiY);
        resolve(Math.round(dpi));
      } else {
        resolve(0);
      }
    } catch (error) {
      reject(error);
    }
  });
};

export const uploadToGallery = (
  acceptedFiles: any,
  addToGallery: any,
  drawingBoard: Canvas | undefined,
  files: any[],
  productType?: string,
  setIsAnglesLoading?: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  if (acceptedFiles) {
    const image = acceptedFiles[0];
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.addEventListener('load', async () => {
      if (reader.result) {
        const formData = new FormData();
        formData.set('images', image);
        await addToGallery({
          formData,
        }).unwrap();
        if (setIsAnglesLoading) setIsAnglesLoading(true);
        addCustomImage(
          acceptedFiles,
          drawingBoard,
          files,
          false,
          false,
          '',
          productType,
        );
      }
    });
  }
};

export const onDropRejectedHandler = (fileRejections: FileRejection[]) => {
  const isFileSizeExceeded = fileRejections.some((rejection) =>
    rejection.errors.some((error) => error.code === 'file-too-large'),
  );
  if (isFileSizeExceeded) {
    toast.warning(
      'Warning: One or more images exceed the maximum allowed size of 25MB.',
    );
  }
};

export async function updateSrcInEditorData(editorData: any): Promise<any> {
  function updateSrcInObjects(objects: any) {
    objects.forEach((obj: any) => {
      if (obj.type === 'custom-image' && obj.originalSrc) {
        obj.src = obj.originalSrc;
        if (obj?.originalImageBlobSrc) delete obj.originalImageBlobSrc;
      }
    });
  }

  return new Promise((resolve) => {
    try {
      for (const key in editorData) {
        if (key === 'front' || key === 'back') {
          if (editorData.hasOwnProperty(key) && editorData[key] !== null) {
            try {
              let data = JSON.parse(editorData[key]);

              if (data.objects && Array.isArray(data.objects)) {
                updateSrcInObjects(data.objects);
                editorData[key] = JSON.stringify(data);
              }
            } catch (e) {
              console.error(`Error parsing JSON for key ${key}:`, e);
            }
          }
        }
      }
      resolve(editorData);
    } catch (e) {
      console.error(`Error parsing JSON for key}:`, e);
    }
  });
}

export const findVariantByVariantIdAndConnectedVariants = (
  variants: VariantsV2[] | undefined,
  searchId: string | undefined,
) => {
  if (searchId && variants) {
    // First, search for a match in the root id field
    let foundObject = variants.find((item) => item.id === searchId);

    // If no match is found, search in the connectedVariants
    if (!foundObject) {
      foundObject = variants.find((item: { connectedVariants: any[] }) =>
        item.connectedVariants.some((variant) => variant.id === searchId),
      );
    }
    return foundObject;
  }
};

export function findMatchingObject(array1: any, array2: any) {
  return new Promise((resolve, reject) => {
    // Iterate over array1 to check for matches
    for (const item1 of array1) {
      for (const item2 of array2) {
        // Check if item1's id matches item2's id or is in item2's connectedVariants
        if (
          item1.id === item2.id ||
          item2.connectedVariants.includes(item1.id)
        ) {
          return resolve(item1); // Resolve the promise with the matching object
        }

        // Check if item2's id matches item1's id or is in item1's connectedVariants
        if (
          item2.id === item1.id ||
          item1.connectedVariants.includes(item2.id)
        ) {
          return resolve(item1); // Resolve the promise with the matching object
        }
      }
    }
    return resolve(null); // If no match is found, resolve with null
  });
}
