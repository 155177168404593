import React, { useState } from 'react';
import { FlexBox } from '../../../common/wrappers';
import Modal from '../../../common/modal';
import MugViewer from './MugViewer';
import { useAppDispatch } from '../../../../store/hooks';

import { ColorPicker } from '../../../mockups/right-sidebar/color-picker';
import Button from '../../../common/button';
import { colors } from '../../../../assets/colors';
import { Cross } from '../../../../assets/icons';
import { updateMockups } from '../../../../store/slices/mockupSlice';

import styled from 'styled-components';
import { nanoid } from '@reduxjs/toolkit';
import {
  VariantEditorDataV2,
  VariantsV2,
} from '../../../../interface/catalog-interface-v2';
import { useLocation } from 'react-router-dom';

export interface SnapImage {
  id: string;
  imageUrl: string;
}
interface MugPreviewModalProps {
  selectedColor: any;
  isColoredMug: boolean;
  onClose?: () => void;
  snapImages: SnapImage[];
  setSnapImages: any;
  variants?: VariantsV2[] | any;
  variantEditorData?: VariantEditorDataV2[] | any;
  defaultEditorData?: { frontimage: string };
  model: '15OZ' | '11OZ';
}
const MugPreviewModal: React.FC<MugPreviewModalProps> = ({
  selectedColor,
  isColoredMug,
  onClose,
  snapImages,
  setSnapImages,
  variants,
  variantEditorData,
  defaultEditorData,
  model,
}) => {
  const location = useLocation();
  const imageData = location.state?.imagesData ?? null;

  const getVariantSpecificImageData = (variant: any) => {
    if (!variantEditorData) {
      return;
    }
    const variantD = variantEditorData?.find(
      (v: { label: string }) => v.label === variant.label,
    );
    if (variantD?.variantSpecificDesign) {
      return variantD?.frontimage;
    } else return defaultEditorData?.frontimage;
  };
  const getDesignFile = (color: any) => {
    return variants?.find(
      (item: { label: string; designFileRequired: any; frontDesign: any }) => {
        if (item.label === color.label) {
          if (!item.designFileRequired) {
            return item.frontDesign;
          }
        }
        return null;
      },
    )?.frontDesign;
  };
  const [color, setColor] = useState(selectedColor);
  const [notification, setNotification] = useState<{
    message: string;
    type: 'success' | 'warning';
  } | null>(null);

  const handleColorChange = (color: any) => {
    setColor(color);
  };
  const dispatch = useAppDispatch();
  const showNotification = (message: string, type: 'success' | 'warning') => {
    setNotification({ message, type });
    setTimeout(() => {
      setNotification(null);
    }, 3000); // 3 seconds
  };

  const handleSnapImage = (dataUrl: string, color: string) => {
    const newColor = color.replace(/\//g, '-');

    if (snapImages.length < 10) {
      setSnapImages((prevSnapImages: SnapImage[]) => [
        ...prevSnapImages,
        { id: `${nanoid(2)}-snap-${newColor}`, imageUrl: dataUrl },
      ]);
      showNotification('Snap Captured', 'success');
    } else {
      showNotification('Maximum of 10 snapshots reached', 'warning');
    }
  };
  const handleRemoveSnapImage = (index: number) => {
    setSnapImages((prevSnapImages: any[]) =>
      prevSnapImages.filter((_, i) => i !== index),
    );
    showNotification('Snap Deleted', 'warning');
  };
  const handleAddingMockup = () => {
    const mockupData = snapImages.map((snap, index) => ({
      id: snap.id,
      image: snap.imageUrl,
      selected: false,
    }));

    dispatch(updateMockups(mockupData));
    handleClose();
  };
  const handleClose = () => {
    if (onClose) onClose();
    setSnapImages([]);
  };

  const variantsOptions = variants?.map(
    (item: { label: string; id: string; colorHex: string }) => ({
      id: item.id,
      label: item.label,
      value: item.colorHex,
    }),
  );
  const getDesignFileByColor = (imagesData: any, color: any) => {
    if (!imagesData) {
      return null;
    }
    for (const key in imagesData) {
      const [colorName, suffix] = key.split('-');
      if (suffix === 'frontDesign' && colorName === color.id) {
        return imagesData[key];
      }
    }
    return imagesData['frontDesign'];
  };

  const designFileForModal = getDesignFileByColor(imageData, color);

  return (
    <StyledWrapper>
      <Modal
        title="Generate Mockups"
        className="mug-preview-modal"
        onClose={onClose && handleClose}
        size="small"
      >
        <>
          {notification && (
            <Notification type={notification.type}>
              {notification.message}
            </Notification>
          )}
          <FlexBox
            justifyContent="center"
            alignItems="center"
            direction="column"
          >
            <FlexBox
              justifyContent="space-between"
              alignItems="center"
              className="color-wrapper"
            >
              <p>Colors</p>
              <ColorPicker
                disabled={false}
                selectedVariantId={color.id ?? color.value}
                onClick={handleColorChange}
                options={variantsOptions}
              />
            </FlexBox>
            <FlexBox direction="column" className="w-100">
              <MugViewer
                image={
                  variantEditorData
                    ? getVariantSpecificImageData(color)
                    : imageData
                    ? designFileForModal
                    : getDesignFile(color)
                }
                selectedColor={color}
                isColoredMug={isColoredMug}
                setSnapImages={handleSnapImage}
                snapImages={snapImages}
                model={model}
              />
            </FlexBox>

            <FlexBox
              direction="row"
              justifyContent="space-between"
              className="snap-image-wrapper"
            >
              <div className="snapshots-container">
                {snapImages.map((snap, index) => (
                  <div key={index} className="snapshot">
                    <div className="snapshot-icons pointer-cursor">
                      <img
                        src={Cross}
                        alt="close"
                        onClick={() => {
                          handleRemoveSnapImage(index);
                        }}
                        style={{
                          height: '14px',
                        }}
                      />
                    </div>
                    <img src={snap.imageUrl} alt={`Snapshot ${index + 1}`} />
                  </div>
                ))}
              </div>
              <Button
                disabled={snapImages.length === 0}
                appearance={'primary'}
                onClick={handleAddingMockup}
                label={'Add a Mockup'}
                className="add-mockup-button"
              />
            </FlexBox>
          </FlexBox>
        </>
      </Modal>
    </StyledWrapper>
  );
};

export default MugPreviewModal;

const StyledWrapper = styled.div`
  .mug-preview-modal {
    height: 520px;
    padding: 0;
    .modal-header {
      width: 95%;
      padding: 10px;
    }
    .color-wrapper {
      background-color: ${colors.grey[100]};
      width: 95%;
      padding: 10px;
    }
    .snapshots-container {
      display: grid;
      grid-template-columns: repeat(5, 50px); /* Display 5 images per row */
      gap: 10px;
    }

    .snapshot {
      margin: 5px;
      position: relative;
      padding-top: 15px;
      width: 50px;
      height: 50px;
      overflow: hidden;
    }

    .snapshot img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .snapshot-icons {
      border-radius: 50%;
      background-color: grey;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;
      padding: 2px;
    }
    .snap-image-wrapper {
      width: 95%;
      margin-top: 14px;
      padding: 10px;
      min-height: 70px;
    }
  }
`;

const Notification = styled.div<{ type: 'success' | 'warning' }>`
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 10px 20px;
  background-color: ${(props) =>
    props.type === 'success' ? colors.green[900] : 'red'};
  color: white;
  border-radius: 5px;
  z-index: 1000;
  font-size: 14px;
`;
