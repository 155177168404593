const fontFamilies = [
  {
    name: 'Arial',
    value: 'Arial, sans-serif',
  },

  {
    name: 'Times New Roman',
    value: 'Times New Roman',
  },

  {
    name: 'Courier New',
    value: '"Courier New", Courier, monospace',
  },

  {
    name: 'Verdana',
    value: 'Verdana, Geneva, sans-serif',
  },
  {
    name: 'Georgia',
    value: 'Georgia, serif',
  },
  {
    name: 'Palatino',
    value: 'Palatino, "Palatino Linotype", serif',
  },
  {
    name: 'Garamond',
    value: '"Garamond", serif',
  },
  {
    name: 'Bookman',
    value: '"Bookman Old Style", serif',
  },
  {
    name: 'Tahoma',
    value: 'Tahoma, Geneva, sans-serif',
  },
  {
    name: 'Trebuchet MS',
    value: '"Trebuchet MS", Helvetica, sans-serif',
  },
  {
    name: 'Arial Black',
    value: '"Arial Black", Gadget, sans-serif',
  },
  {
    name: 'Impact',
    value: 'Impact, Charcoal, sans-serif',
  },
];

export { fontFamilies };
