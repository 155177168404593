import React, { useEffect, useState } from 'react';
import Catalog from '../../components/catalog';
import Banner from '../../components/banner';
import { useAppSelector } from '../../store/hooks';
import { getUserData } from '../../store/slices/authSlice';
import { getCookie } from '../../helper/cookies';
import BaseLayout from '../../components/layout/base-layout';

const HomePage: React.FC = () => {
  const { isGrowth } = useAppSelector(getUserData);
  const [isCssLoaded, setIsCssLoaded] = useState(false);
  const [isBanner, setIsBanner] = useState(() => {
    if (!isGrowth) {
      if (getCookie('showBanner') === 'no') {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  });

  const handleBanner = (value: boolean) => {
    setIsBanner(value);
  };

  useEffect(() => {
    fetch('/tailwind-latest-home.css')
      .then(response => response.text())
      .then(cssContent => {
        const style = document.createElement('style');
        style.id = 'my-component-style';
        style.innerHTML = cssContent;
        document.head.appendChild(style);
        setIsCssLoaded(true);
      });

    return () => {
      const styleElement = document.getElementById('my-component-style');
      if (styleElement) {
        styleElement.remove();
      }
    };
  }, []);

  if (!isCssLoaded) {
    return null;
  }

  return (
    <>
      {isBanner && <Banner handleBanner={handleBanner} />}
      <BaseLayout>
        <Catalog />
      </BaseLayout>
    </>
  );
};

export default HomePage;
