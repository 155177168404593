import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { productsAPI } from '../services/products';
import { Status } from '../../interface';
import { productsExtraReducerData } from '../../helper/productsExtraReducerData';
import {
  ShippingProfile,
  SingleProductVariant,
  SyncingOptions,
} from '../../interface/product-interface';

interface ProductDetails {
  title: string;
  tags: string[];
  description: any;
  variants: SingleProductVariant[];
  descriptionResponse: any;
  publishingSettings: {
    showInStore: boolean;
    variantsVisibility: string;
    syncingOptions: SyncingOptions;
    shippingProfile: string | number;
  };
}
export interface InitialState {
  selectedRows: any[];
  editField: 'mrsp' | 'profit' | 'margin' | 'none';
  sizeVariants: string[];
  productColors: { label: string; colorHex: string }[];

  mockupURL: string;
  productDetails: ProductDetails;
  status: Status;
  shippingProfiles: ShippingProfile[];
  images: { imageUrl: string }[];
  isPersonalizable: boolean;
  personalizationInstructions?: string;
}

const initialState: InitialState = {
  selectedRows: [],
  editField: 'none',
  sizeVariants: [],
  productColors: [],
  mockupURL: '',
  images: [],
  isPersonalizable: false,
  productDetails: {
    title: '',
    tags: [],
    description: '',
    variants: [],
    publishingSettings: {
      showInStore: true,
      syncingOptions: {
        syncProductTitle: true,
        syncDescription: true,
        syncVariants: true,
        syncMockups: true,
        syncShippingProfiles: true,
      },
      shippingProfile: '',
      variantsVisibility: '',
    },
    descriptionResponse: '',
  },
  status: Status.Draft,
  shippingProfiles: [],
  personalizationInstructions: '',
};

export const detailsSlice = createSlice({
  name: 'details',
  initialState,
  reducers: {
    updateSingleData: (
      state,
      {
        payload,
      }: PayloadAction<{ fieldName: string; value: string | boolean | any }>,
    ) => {
      state.productDetails = {
        ...state.productDetails,
        [payload.fieldName]: payload.value,
      };
    },
    updatePublishingSettings: (
      state,
      {
        payload,
      }: PayloadAction<{ fieldName: string; value: string | boolean }>,
    ) => {
      state.productDetails.publishingSettings = {
        ...state.productDetails.publishingSettings,
        [payload.fieldName]: payload.value,
      };
    },
    addTag: (state, { payload }: PayloadAction<string>) => {
      state.productDetails = {
        ...state.productDetails,
        tags: state.productDetails.tags.concat(payload),
      };
    },

    removeTag: (state, { payload }: PayloadAction<{ value: string }>) => {
      state.productDetails = {
        ...state.productDetails,
        tags: state.productDetails.tags.filter((tag) => tag !== payload.value),
      };
    },

    updateEditField: (
      state,
      action: PayloadAction<{
        fieldName: 'mrsp' | 'profit' | 'margin' | 'none';
      }>,
    ) => {
      state.editField = action.payload.fieldName;
    },
    updateSeletedRows: (state, action: PayloadAction<any>) => {
      state.selectedRows = action.payload;
    },
    updateVariantsRowData: (
      state,
      { payload }: PayloadAction<{ newValue: number; id: string }[]>,
    ) => {
      payload.forEach((newObj) => {
        state.productDetails.variants.forEach((variant, variantIndex) => {
          if (variant.id === newObj.id) {
            state.productDetails.variants[variantIndex].mrsp = newObj.newValue
              ? newObj.newValue
              : state.productDetails.variants[variantIndex].cost;
          }
        });
      });

      /** after updating empty the selected rows and close the popup*/
      state.selectedRows = [];
      state.editField = 'none';
    },

    updateSyncingOptions: (
      state,
      { payload }: PayloadAction<{ key: keyof SyncingOptions; value: boolean }>,
    ) => {
      state.productDetails.publishingSettings.syncingOptions[payload.key] =
        payload.value;
    },
    updateIsPersonalization: (
      state,
      { payload }: PayloadAction<{ value: boolean }>,
    ) => {
      state.isPersonalizable = payload.value;
    },
    updatePersonalizationInstructions: (
      state,
      { payload }: PayloadAction<{ value: string }>,
    ) => {
      state.personalizationInstructions = payload.value;
    },
    updateImages: (
      state,
      { payload }: PayloadAction<{ imageUrl: string }[]>,
    ) => {
      state.images = payload;
    },
    resetDetailsSlice: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      productsAPI.endpoints.getProductDetailsForDetailsPage.matchFulfilled,
      (state, { payload }) => {
        return productsExtraReducerData(state, payload);
      },
    );
  },
});

export const {
  updateSingleData,
  updatePublishingSettings,
  updateSeletedRows,
  updateVariantsRowData,
  updateEditField,
  addTag,
  removeTag,
  updateSyncingOptions,
  updatePersonalizationInstructions,
  updateIsPersonalization,
  resetDetailsSlice,
  updateImages,
} = detailsSlice.actions;

export default detailsSlice.reducer;

export const getDetails = (state: RootState): InitialState => state.details;
export const productDetails = (state: RootState): ProductDetails =>
  state.details.productDetails;
