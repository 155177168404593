import { GridColDef } from '@mui/x-data-grid-pro';
import { RenderText, RenderTextHeader } from '../../../utils/render-cells';

export const getVariantColumns = (
  currentCurrency?: string,
  newCurrency?: string,
) => {
  const variantsColumns: GridColDef[] = [
    {
      field: 'size',
      headerName: 'Size',
      disableReorder: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderHeader: RenderTextHeader,
      renderCell: RenderText,
    },
    {
      field: 'color',
      headerName: 'Color',
      disableReorder: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderHeader: RenderTextHeader,
      renderCell: RenderText,
    },
    {
      field: 'cost',
      headerName: 'Cost',
      disableReorder: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderHeader: RenderTextHeader,
      renderCell: RenderText,
    },
    {
      field: 'mrsp',
      headerName: 'Retail Price',
      disableReorder: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1.2,
      renderHeader: RenderTextHeader,
      renderCell: RenderText,
    },
    ...(currentCurrency !== newCurrency
      ? [
          {
            field: 'mrspInCurrency',
            headerName: `Retails Price ${newCurrency}`,
            disableReorder: true,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            flex: 1,
            renderHeader: RenderTextHeader,
            renderCell: RenderText,
          },
        ]
      : []),
    {
      field: 'profit',
      headerName: 'Profit',
      disableReorder: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderHeader: RenderTextHeader,
      renderCell: RenderText,
    },
    ...(currentCurrency !== newCurrency
      ? [
          {
            field: 'profitInCurrency',
            headerName: `Profit ${newCurrency}`,
            disableReorder: true,
            sortable: false,
            filterable: false,
            disableColumnMenu: true,
            flex: 1,
            renderHeader: RenderTextHeader,
            renderCell: RenderText,
          },
        ]
      : []),
    {
      field: 'margin',
      headerName: 'Margin',
      disableReorder: true,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      flex: 1,
      renderHeader: RenderTextHeader,
      renderCell: RenderText,
    },
  ];
  return variantsColumns;
};
